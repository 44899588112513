import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, map, mergeMap, Subject, takeWhile, tap, timer } from 'rxjs';
import { AlertActionModel, AlertModel } from '../models/alert.model';
import { v4 as uuidv4 } from 'uuid';
import { TranslateService } from '@ngx-translate/core';

const UPDATE_INTERVAL = 150;
const SECONDS_SHOWN = 8;

@Injectable({
    providedIn: 'root',
})
export class AlertsService {
    alerts$ = new BehaviorSubject<AlertModel[]>([]);
    progresses$ = new BehaviorSubject<{ [key: string]: number }>({});
    shown$ = new Subject<string>();
    hovered: string[] = [];

    constructor(private translateService: TranslateService) {
        this.init();
    }

    show(message: string, severity: 'danger' | 'success' | 'info' | 'warning', translate = true, area = 'window', action: AlertActionModel | null = null) {
        this.push({
            id: uuidv4(),
            message: translate ? <string>this.translateService.instant(message) : message,
            severity,
            area,
            action: action
                ? {
                      ...action,
                      link: translate ? <string>this.translateService.instant(action.link) : action.link,
                  }
                : null,
        });
    }

    shown(id: string) {
        this.shown$.next(id);
    }

    hide(id: string) {
        this.alerts$.next([...this.alerts$.value].filter((alert) => alert.id !== id));
        this.onMouseLeave(id);
        const progresses = this.progresses$.value;
        if (progresses[id]) {
            delete progresses[id];
        }
        this.progresses$.next(progresses);
    }

    hideAll() {
        this.alerts$.value.forEach((alert) => {
            this.hide(alert.id);
        });
    }

    onMouseEnter(id: string) {
        if (!this.hovered.includes(id)) {
            this.hovered.push(id);
        }
    }

    onMouseLeave(id: string) {
        if (this.hovered.indexOf(id) !== -1) {
            this.hovered.splice(this.hovered.indexOf(id), 1);
        }
    }

    private push(alert: AlertModel) {
        let alerts = [...this.alerts$.value];

        const alertAlreadyExist = alerts.some((e) => e.message === alert.message);
        if (alertAlreadyExist) {
            alerts = alerts.filter((e) => e.message !== alert.message);
        }

        alerts.unshift(alert);

        if (alerts.length > 5) {
            alerts.pop();
        }

        this.alerts$.next(alerts);
    }

    private init() {
        this.shown$
            .pipe(
                mergeMap((id) => {
                    this.progresses$.next({
                        ...this.progresses$.value,
                        [id]: 0,
                    });
                    const totalTicks = (SECONDS_SHOWN * 1000) / UPDATE_INTERVAL;
                    let ticks = totalTicks;
                    return timer(0, UPDATE_INTERVAL).pipe(
                        map(() => {
                            if (!this.hovered.includes(id)) {
                                ticks--;
                            }
                            this.progresses$.next({
                                ...this.progresses$.value,
                                [id]: ticks / totalTicks,
                            });
                            return ticks;
                        }),
                        takeWhile((value) => value >= 0, true),
                        filter((value) => value <= 0),
                        map(() => id),
                    );
                }),
                tap((id: string) => this.hide(id)),
            )
            .subscribe();
    }
}
