import { createReducer, on } from '@ngrx/store';
import { User } from 'app/store/chat/users';
import { conversationActions } from '../../conversation/actions/conversation.actions';
import { usersActions } from '../actions/users.actions';
import { Message } from '../../conversation';

const initialState = {
    current_user: null as User | null,
    selected_user: null as string | null,
    users: [] as User[],
    typing: new Map<string, boolean>(),
    unread_messages: new Map<string, number>(),
    last_count: 0,
    page: 1,
    can_load_more: true,
};

export type UsersState = typeof initialState;

export const usersReducer = createReducer(
    {
        ...initialState,
    },
    on(usersActions.reset, (): UsersState => {
        return {
            ...initialState,
        };
    }),
    on(usersActions.setUsers, (state, payload): UsersState => {
        return {
            ...state,
            users: payload.value,
        };
    }),
    on(usersActions.putUser, (state, { value }): UsersState => {
        let users = [...state.users];

        if (state.users.some((user) => user.id === value.id)) {
            users = users.map((user) => (value.id === user.id ? value : user));
        } else {
            users.unshift(value);
        }

        return {
            ...state,
            users,
        };
    }),
    on(usersActions.putUsers, (state, payload): UsersState => {
        const last_count = payload.value.length;
        const can_load_more = last_count !== 0;
        const page = can_load_more ? state.page + 1 : state.page;
        const users = new Map<string, User>(state.users.map((user) => [user.id, user]));

        payload.value.forEach((user) => {
            if (users.has(user.id)) {
                return;
            }

            users.set(user.id, user);
        });

        return {
            ...state,
            users: Array.from(users.values()),
            last_count,
            can_load_more,
            page,
        };
    }),
    on(usersActions.selectUser, (state, payload): UsersState => {
        return {
            ...state,
            selected_user: payload.value,
        };
    }),
    on(usersActions.currentUser, (state, payload): UsersState => {
        return {
            ...state,
            current_user: payload.value,
        };
    }),
    on(usersActions.typingUser, (state, payload): UsersState => {
        const map = new Map(state.typing);

        map.set(payload.value.user, payload.value.typing);

        return {
            ...state,
            typing: map,
        };
    }),
    on(usersActions.setUserStatus, (state, payload): UsersState => {
        const users = state.users.map<User>((user) => (user.id === payload.userId ? { ...user, status: payload.status } : user));

        return {
            ...state,
            users,
        };
    }),
    on(usersActions.setCurrentUserStatus, (state, payload): UsersState => {
        const current_user = state.current_user
            ? {
                  ...state.current_user,
                  status: payload.status,
              }
            : null;

        return {
            ...state,
            current_user,
        };
    }),
    on(usersActions.assignUser, (state, payload): UsersState => {
        const selected_user = payload.selectedUser ?? state.selected_user;
        const users = state.users.filter((user) => user.id !== payload.user.id);
        users.unshift(payload.user);

        return {
            ...state,
            users,
            selected_user,
        };
    }),
    on(usersActions.unassignUser, (state, payload): UsersState => {
        const userId = payload.user.id;
        const isRoleClient = state.current_user?.roles?.includes('ROLE_CLIENT');
        const filteredUsers = state.users.filter((user) => user.id !== userId);

        const selected_user = !isRoleClient && state.selected_user === userId ? null : state.selected_user;

        return {
            ...state,
            users: filteredUsers,
            selected_user,
        };
    }),
    on(conversationActions.sendMessageSuccess, (state): UsersState => {
        if (state.current_user?.roles?.includes('ROLE_CLIENT')) {
            return { ...state };
        }

        /** Move user to the top after message has been sent */
        const selectedUser = state.users.find((user) => user.id === state.selected_user);
        const users = state.users.filter((user) => user.id !== state.selected_user);

        if (selectedUser) {
            users.unshift(selectedUser);
        }

        return {
            ...state,
            users,
        };
    }),

    on(usersActions.reduceUserHangouts, (state, payload): UsersState => {
        const users = state.users.map((user) => {
            if (user.id === payload.userId) {
                const videoCounts = user?.videoCounts
                    ? { ...user.videoCounts, videoCount: user.videoCounts.videoCount > 0 ? user.videoCounts.videoCount - 1 : 0 }
                    : null;

                return { ...user, videoCounts };
            }

            return user;
        });

        return {
            ...state,
            users,
        };
    }),

    on(usersActions.setUserHangouts, (state, payload): UsersState => {
        const users = state.users.map((user) => {
            if (user.id === payload.userId) {
                const videoCounts = user?.videoCounts ? { ...user.videoCounts, videoCount: payload.videoCount } : null;

                return { ...user, videoCounts };
            }

            return user;
        });

        return {
            ...state,
            users,
        };
    }),
    on(usersActions.putUnreadMessages, (state, payload): UsersState => {
        const unread_messages = new Map(state.unread_messages);

        payload.value.forEach((item) => {
            unread_messages.set(item.userFromId, item.unread);
        });

        return {
            ...state,
            unread_messages,
        };
    }),
    on(usersActions.setUnreadMessages, (state, payload): UsersState => {
        const unread_messages = new Map(state.unread_messages);

        unread_messages.set(payload.value.userFromId, payload.value.unread);

        return {
            ...state,
            unread_messages,
        };
    }),
    on(conversationActions.newMessage, (state, { value }): UsersState => {
        const unread_messages = new Map(state.unread_messages);
        const userId = state.current_user?.id === value.userFromId ? value.userToId : value.userFromId;

        const user = state.users.find((user) => user.id === userId);
        const users = state.users.filter((user) => user.id !== userId);

        if (user) {
            users.unshift(user);
        }

        if (state.selected_user !== userId) {
            const unreadCount = unread_messages.get(userId) ?? 0;
            unread_messages.set(userId, unreadCount + 1);
        }

        return { ...state, unread_messages, users };
    }),
    on(conversationActions.sendMessageSuccess, (state, payload): UsersState => {
        // Update lastMessageTime when employee sends message

        let message: Message | undefined;

        if (Array.isArray(payload.response)) {
            const msg = payload.response.find((msg) => msg.userToId);

            if (msg) {
                message = msg;
            }
        } else {
            message = payload.response;
        }

        if (message?.offline || state.current_user?.roles?.includes('ROLE_CLIENT')) {
            return state;
        }

        const users = state.users.map((user) => {
            if (message && user.id === message.userToId) {
                return { ...user, lastMessageTime: message.created * 1000 };
            }

            return user;
        });

        return { ...state, users };
    }),
    on(conversationActions.newMessage, (state, payload): UsersState => {
        // Update lastMessageTime on users list on new message

        const message = payload.value;

        if (message.offline || state.current_user?.roles?.includes('ROLE_CLIENT')) {
            return state;
        }

        const users = state.users.map((user) => {
            if (state.current_user?.id === message.userFromId) {
                if (user.id === message.userToId) {
                    return { ...user, lastMessageTime: message.created * 1000 };
                }
            } else {
                if (user.id === message.userFromId) {
                    return { ...user, lastMessageTime: message.created * 1000 };
                }
            }

            return user;
        });

        return { ...state, users };
    }),
);
