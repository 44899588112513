import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { selectPlans, selectLoaded, selectLoading } from '../selectors/plans.selector';
import { plansActions } from '../actions/plans.actions';
import { PlanModel } from '../models';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class PlansFacade {
    plans$ = this.store.select(selectPlans).pipe(filter((plans): plans is PlanModel[] => plans !== null));
    loaded$ = this.store.select(selectLoaded).pipe(filter((loaded) => loaded !== null));
    loading$ = this.store.select(selectLoading).pipe(filter((loading) => loading !== null));
    constructor(
        private store: Store,
        private translateService: TranslateService,
    ) {}

    plansSelectOptions$ = this.plans$.pipe(
        map((plans) =>
            plans.map((plan) => ({
                name: `${plan.name} ${plan.duration === '+1 week' ? this.translateService.instant('weekly-plan') : ''}`,
                value: plan.id,
            })),
        ),
    );

    getPlans(): void {
        this.store.dispatch(plansActions.getPlansAction());
    }

    clear(): void {
        this.store.dispatch(plansActions.clearAction());
    }
}
