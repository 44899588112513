import {
    Renderer2,
    Component,
    ElementRef,
    AfterViewInit,
    ViewChild,
    Inject,
    ChangeDetectionStrategy,
    OnInit,
    OnDestroy,
    Input,
    ChangeDetectorRef,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, distinctUntilChanged, filter, skip, Subscription, take, tap } from 'rxjs';
import { MediaService } from '@app/shared/services/media.service';

@Component({
    selector: 'app-menu-dropdown',
    templateUrl: './menu-dropdown.component.html',
    styleUrls: ['./menu-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuDropdownComponent implements AfterViewInit, OnInit, OnDestroy {
    @ViewChild('menuDropdown', { static: false }) menuDropdown!: ElementRef<HTMLDivElement>;
    @ViewChild('hoverItem', { static: false }) hoverItem!: ElementRef<HTMLDivElement>;
    @ViewChild('menuItem', { static: false }) menuItem!: ElementRef<HTMLDivElement>;

    @Input()
    position = 'left';

    private hover$ = new BehaviorSubject(false);
    sm$ = this.mediaService.media('sm');

    private readonly subscriptions$ = new Subscription();
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private mediaService: MediaService,
    ) {}

    ngOnInit(): void {
        this.subscriptions$.add(
            this.hover$
                .pipe(
                    skip(1),
                    distinctUntilChanged(),
                    tap((open) => {
                        open
                            ? this.renderer.appendChild(this.document.body, this.menuItem.nativeElement)
                            : this.renderer.removeChild(document.body, this.menuItem.nativeElement);
                        this.changeDetectorRef.detectChanges();
                    }),
                )
                .subscribe(),
        );

        this.subscriptions$.add(
            this.router.events
                .pipe(
                    filter((event) => event instanceof NavigationStart && this.hover$.value === true),
                    tap(() => this.hover$.next(false)),
                )
                .subscribe(),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    get top(): number {
        const boundingRect = this.hoverItem?.nativeElement.getBoundingClientRect();
        return boundingRect?.top + boundingRect?.height + 16 || 0 + 16;
    }

    get left() {
        const boundingRect = this.hoverItem?.nativeElement.getBoundingClientRect();
        if (this.position === 'left') {
            return boundingRect?.left || 0;
        }
        const boundingRectDropdown = this.menuItem?.nativeElement.getBoundingClientRect();
        return boundingRect?.right - boundingRectDropdown?.width || 0;
    }

    ngAfterViewInit(): void {
        this.renderer.removeChild(this.menuDropdown.nativeElement, this.menuItem.nativeElement);
    }

    onEnter(): void {
        this.sm$
            .pipe(
                take(1),
                tap((isLarge) => {
                    if (isLarge) {
                        this.hover$.next(true);
                    }
                }),
            )
            .subscribe();
    }

    onClick(): void {
        this.sm$
            .pipe(
                take(1),
                tap((isLarge) => {
                    if (!isLarge) {
                        this.hover$.next(!this.hover$.value);
                    }
                }),
            )
            .subscribe();
    }

    onLeave(): void {
        this.sm$
            .pipe(
                take(1),
                tap((isLarge) => {
                    if (isLarge) {
                        this.hover$.next(false);
                    }
                }),
            )
            .subscribe();
    }

    onMenuEnter(): void {
        this.hover$.next(true);
    }

    onMenuLeave(): void {
        this.hover$.next(false);
    }

    onMenuItemClick(): void {
        this.hover$.next(false);
    }
}
