import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoleNamesPipe } from './pipes/roles-names.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [RoleNamesPipe],
    exports: [RoleNamesPipe],
})
export class PrivilegesModule {}
