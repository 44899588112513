import { Injectable, OnDestroy } from '@angular/core';
import { SocketService } from '@app/shared/services/socket.service';
import { createEffect } from '@ngrx/effects';
import { SocketError } from '../../chat/conversation/models/error.model';
import { Subscription, catchError, filter, fromEventPattern, map, share, skip, take, tap, throttleTime, throwError } from 'rxjs';
import { AlertsService } from '@app/shared/alerts';
import { TokenService } from '@app/shared';
import { AuthService } from '@app/core/auth';
import { User, UserDto } from '../../chat/users/models';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { CleanupService } from '@app/store/services/cleanup.service';

@Injectable()
export class WebsocketEffects implements OnDestroy {
    private socket = this.socketService.socket;

    errorEmitter$ = fromEventPattern<SocketError>((handler) => {
        this.socket.on('error', (data: SocketError) => {
            handler(data);
        });
    }).pipe(share());

    authErrorEmitter$ = fromEventPattern<SocketError>((handler) => {
        this.socket.on('auth-error', (data: SocketError) => {
            handler(data);
        });
    }).pipe(share());

    currentUserEmitter$ = fromEventPattern<User>((handler) => {
        this.socket.on('current-user', (userDto: UserDto) => {
            handler(new User(userDto));
        });
    }).pipe(share());

    runEmitsOnConnect$ = createEffect(
        () => {
            return this.currentUserEmitter$.pipe(
                skip(1),
                tap(() => {
                    this.socketService.emits.forEach((emit) => {
                        emit();
                    });
                }),
            );
        },
        { dispatch: false },
    );

    errorListener$ = createEffect(
        () => {
            return this.errorEmitter$.pipe(
                tap((error) => {
                    if (error.errorType === 'unauthenticated') {
                        return;
                    }

                    if (typeof error.message === 'string' && error.message !== '') {
                        this.alertsService.show(error.message, 'danger');
                    }

                    error.data?.forEach((item) => {
                        this.alertsService.show(item.message, 'danger');
                    });
                }),
            );
        },
        { dispatch: false },
    );

    authErrorListener$ = createEffect(
        () => {
            return this.authErrorEmitter$.pipe(
                tap(() => {
                    this.refreshToken('init-new-token');
                }),
            );
        },
        { dispatch: false },
    );

    uanthenticatedErrorListener$ = createEffect(
        () => {
            return this.errorEmitter$.pipe(
                filter((error) => error.errorType === 'unauthenticated'),
                throttleTime(10000),
                tap(() => {
                    this.refreshToken('refresh-token');
                }),
            );
        },
        { dispatch: false },
    );

    private readonly subscriptions$ = new Subscription();

    constructor(
        private socketService: SocketService,
        private alertsService: AlertsService,
        private authService: AuthService,
        private tokenService: TokenService,
        private router: Router,
        private cleanupService: CleanupService,
    ) {}

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    private refreshToken(emitKey: 'init-new-token' | 'refresh-token') {
        const refreshToken$ = this.authService.refresh();

        this.subscriptions$.add(
            refreshToken$
                .pipe(
                    map(() => this.tokenService.getToken()),
                    take(1),
                    tap((userToken) => {
                        this.socket.emit(emitKey, { userToken });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        if (error.status === 401) {
                            this.alertsService.show('auth.token.expired', 'danger');
                            this.cleanupService.cleanup();
                            void this.router.navigate(['/']);
                        }
                        return throwError(() => error);
                    }),
                )
                .subscribe(),
        );
    }
}
