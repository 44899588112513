import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { PlansFacade } from '@app/store/plans';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class PlansPreloadGuard  {
    constructor(private plansFacade: PlansFacade) {}

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.plansFacade.loaded$.pipe(
            tap((loaded) => {
                if (loaded !== true) {
                    this.plansFacade.getPlans();
                }
            }),
            filter((loaded) => loaded === true),
            take(1),
            map(() => true),
        );
    }
}
