import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MessagesState } from '../reducers/messages.reducer';
import { MESSAGES_FEATURE_KEY } from '../keys';

const selectState = createFeatureSelector<MessagesState>(MESSAGES_FEATURE_KEY);

export const selectMessages = createSelector(selectState, (state: MessagesState) => {
    return state.messages;
});

export const selectLoaded = createSelector(selectState, (state: MessagesState) => {
    return state.loaded;
});

export const selectLoading = createSelector(selectState, (state: MessagesState) => {
    return state.loading;
});

export const selectErrors = createSelector(selectState, (state: MessagesState) => {
    return state.errors;
});

export const selectTotalItems = createSelector(selectState, (state: MessagesState) => {
    return state.pagination.totalItems;
});
