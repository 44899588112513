import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { AuthenticatedUserFacade } from '@app/store/authenticated-user';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class UserPreloadGuard  {
    constructor(private authenticatedUserFacade: AuthenticatedUserFacade) {}

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.authenticatedUserFacade.loaded$.pipe(
            tap((loaded) => {
                if (loaded === null) {
                    this.authenticatedUserFacade.loadUserGracefull();
                }
            }),
            filter((loaded) => loaded !== null),
            take(1),
            map(() => true),
        );
    }
}
