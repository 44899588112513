import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CredentailsModel, LoginResponseModel } from '@app/account/models';
import { ApiResponse } from '@app/shared/models';
import { map, Observable } from 'rxjs';
import { LoginModel, RegisterModel } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private httpClient: HttpClient) {}

    register(value: RegisterModel): Observable<unknown> {
        const params: RegisterModel = {
            ...value,
            email: value.email.trim().toLocaleLowerCase(),
        };

        return this.httpClient
            .addServer()
            .skipToken()
            .skipRefreshToken()
            .skipErrorHandler()
            .post<ApiResponse<unknown>>(`api/v1/public/register`, params)
            .pipe(
                map((response) => {
                    if (response.success === false) {
                        throw response.data;
                    }
                    return response;
                }),
                map((response) => response.data),
            );
    }

    login(value: LoginModel): Observable<CredentailsModel> {
        const params = {
            email: value.email.trim().toLocaleLowerCase(),
            password: value.password,
        };

        return this.httpClient
            .addServer()
            .addClientIpHeader()
            .skipRefreshToken()
            .post<LoginResponseModel>(`api/v1/login`, params, { responseType: 'json', observe: 'response' })
            .pipe(
                map((response) => {
                    const r_key = response.headers.get('R-Key');

                    return { ...response.body, r_key } as CredentailsModel;
                }),
            );
    }

    logout(): Observable<unknown> {
        return this.httpClient.addServer().post<ApiResponse<unknown>>(`api/v1/logout`, {});
    }

    requestResetPassword(email: string): Observable<unknown> {
        const params = { email: email.trim().toLocaleLowerCase() };
        return this.httpClient.addServer().skipRefreshToken().post<ApiResponse<unknown>>(`api/v1/public/send_reset`, params);
    }

    resetPassword(password: string, token: string): Observable<unknown> {
        const params = { password, token };
        return this.httpClient
            .addServer()
            .skipRefreshToken()
            .post<ApiResponse<unknown>>(`api/v1/public/reset`, params)
            .pipe(
                map((response) => {
                    if (response.success === false) {
                        throw response;
                    }
                    return response;
                }),
            );
    }

    activate(token: string): Observable<unknown> {
        return this.httpClient.addServer().skipToken().skipRefreshToken().post<ApiResponse<unknown>>(`api/v1/public/confirm`, {
            token,
        });
    }
}
