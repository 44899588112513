import { Component, OnInit } from '@angular/core';
import { LanguageService } from './i18n';
import { LoaderService } from './store/loader';
import { environment } from '../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [LanguageService],
})
export class AppComponent implements OnInit {
    constructor(
        private loaderService: LoaderService,
        private languageService: LanguageService,
    ) {
        this.loadPayU();
    }

    ngOnInit() {
        this.loaderService.init();
        this.languageService.init();
    }

    loadPayU() {
        if (environment.payuUrl) {
            const node: HTMLScriptElement = document.createElement('script');
            node.src = environment.payuUrl;
            node.type = 'text/javascript';
            const head = document.querySelector('head');

            if (!head) {
                return;
            }

            head.appendChild(node);
        }
    }
}
