import { Injectable } from '@angular/core';

import { StorageModel } from '../models/storage.model';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    public setItem(key: string, value: unknown): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    public removeItem(key: string): void {
        localStorage.removeItem(key);
    }

    public getItem(item: string): StorageModel | string | null {
        try {
            const storage = localStorage.getItem(item);
            if (storage) {
                return JSON.parse(storage) as { [key: string]: unknown };
            } else {
                throw '!';
            }
        } catch (error) {
            return null;
        }
    }
}
