import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

type Breakpoint = keyof typeof BREAKPOINTS;

const BREAKPOINTS = {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px',
};

@Injectable({
    providedIn: 'root',
})
export class MediaService {
    // eslint-disable-next-line @typescript-eslint/ban-types
    media(value: Breakpoint | (string & {})): Observable<boolean> {
        const query = this.getMediaQuery(value);
        const mediaQuery = window.matchMedia(query);

        return fromEvent<MediaQueryList>(mediaQuery, 'change').pipe(
            startWith(mediaQuery),
            map((list: MediaQueryList) => list.matches),
        );
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    checkMedia(value: Breakpoint | (string & {})): boolean {
        const query = this.getMediaQuery(value);
        const mediaQuery = window.matchMedia(query);

        return mediaQuery.matches;
    }

    getMediaQuery(value: string) {
        return value in BREAKPOINTS ? `(min-width: ${BREAKPOINTS[value as Breakpoint]})` : value;
    }
}
