<ng-container *ngIf="static; then staticTemplate; else floatTemplate"></ng-container>

<ng-template #staticTemplate>
    <div class="container">
        <div class="alerts alerts-static">
            <div @Grow *ngFor="let alert of alerts$ | async; let last = last">
                <app-alert [alert]="alert" [last]="last"></app-alert>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #floatTemplate>
    <div class="alerts alerts-float">
        <div @Grow *ngFor="let alert of alerts$ | async; let last = last">
            <app-alert [alert]="alert" [last]="last"></app-alert>
        </div>
    </div>
</ng-template>
