<div>
    <img class="logo d-block" src="assets/images-optimized/logo-wetalk.png" />
    <div class="d-flex">
        <div class="ms-12 me-auto">
            <div class="spinner me-auto"></div>
        </div>
        <div class="me-10 ms-auto">
            <div class="spinner ms-auto"></div>
        </div>
    </div>
</div>
