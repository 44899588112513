import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, filter, fromEventPattern, map, startWith, take, tap, throttleTime, timer } from 'rxjs';
import { User } from 'app/store/chat/users';
import { distinctUntilChanged, mergeMap, share, switchMap } from 'rxjs/operators';
import { SocketService } from '@shared/services/socket.service';
import { Typing, TypingResponse, UnreadMessagesResponse, UserDto } from '@app/shared/models/user.model';
import { getStatus, getStatusAsNumber, UserStatus } from '@shared/models/user-status.model';
import { usersSelectors } from '../selectors/users.selector';
import { Store } from '@ngrx/store';
import { USERS_LIMIT } from '@app/store/chat/users/config/users.config';
import { conversationSelectors } from '@app/store/chat/conversation/selectors/conversation.selector';
import { Router } from '@angular/router';
import { conversationActions } from '../../conversation/actions/conversation.actions';
import { hangoutActions } from '@app/store/hangout/actions/hangout.actions';
import { usersActions } from '../actions/users.actions';
import { authenticatedUserActions } from '@app/store/authenticated-user/actions/authenticated-user.actions';
import { VideoChangeDto } from '../models/video-change.dto';

@Injectable()
export class UsersEffects {
    private socket = this.socketService.socket;

    /** Emitters */
    currentUserEmitter$ = fromEventPattern<User>((handler) => {
        this.socket.on('current-user', (userDto: UserDto) => {
            handler(new User(userDto));
        });
    }).pipe(share());

    userAssignedEmitter$ = fromEventPattern<{ user: UserDto; userAssigned: UserDto }>((handler) => {
        this.socket.on('user-assigned', ({ user, userAssigned }: { user: UserDto; userAssigned: UserDto }) => {
            handler({ user, userAssigned });
        });
    }).pipe(share());

    userUnassignedEmitter$ = fromEventPattern<{ user: UserDto; userUnassigned: UserDto }>((handler) => {
        this.socket.on('user-unassigned', ({ user, userUnassigned }: { user: UserDto; userUnassigned: UserDto }) => {
            handler({ user, userUnassigned });
        });
    }).pipe(share());

    userSubscriptionActivatedEmitter$ = fromEventPattern<string>((handler) => {
        this.socket.on('user-subscription-activated', (value: { data: { id: string } }) => {
            handler(value.data.id);
        });
    }).pipe(share());

    userSubscriptionInactivatedEmitter$ = fromEventPattern<string>((handler) => {
        this.socket.on('user-subscription-inactivated', (value: { data: { id: string } }) => {
            handler(value.data.id);
        });
    }).pipe(share());

    userVideoChangeEmitter$ = fromEventPattern<{ userId: string }>((handler) => {
        this.socket.on('user-video-change', (value: VideoChangeDto) => {
            handler({ userId: value.data.id });
        });
    }).pipe(share());

    typingEmitter$ = fromEventPattern<Typing>((handler) => {
        this.socket.on('typing', (data: TypingResponse) => {
            handler({
                user: data.from,
                typing: true,
            });
        });
    }).pipe(
        switchMap((typing) => {
            return timer(2000).pipe(
                map(() => {
                    return {
                        ...typing,
                        typing: false,
                    };
                }),
                startWith(typing),
            );
        }),
        distinctUntilChanged(),
        share(),
    );

    userStatusEmitter$ = fromEventPattern<{ userId: string; status: UserStatus }>((handler) => {
        this.socket.on('change-status', ({ status, id }: UserDto) => {
            handler({ userId: id, status: getStatus(status) });
        });
    }).pipe(share());

    userConnectedEmitter$ = fromEventPattern<{ userId: string; connected: boolean; status: UserStatus }>((handler) => {
        this.socket.on('user-disconnected', ({ id }: UserDto) => {
            handler({ userId: id, status: 'offline' });
        });

        this.socket.on('user-connected', ({ id }: UserDto) => {
            handler({ userId: id, status: 'online' });
        });
    }).pipe(share());

    /** Emitter listeners */
    userAssignedListener$ = createEffect(() => {
        return this.userAssignedEmitter$.pipe(
            concatLatestFrom(() => this.store.select(usersSelectors.selectCurrentUser)),
            map(([{ user, userAssigned }, currentUser]) => {
                const isEmployee = currentUser?.id === user.id;
                const userAddToList = isEmployee ? userAssigned : user;
                const newSelectedUser = isEmployee ? undefined : user.id;

                if (userAddToList?.room?.id) {
                    this.socketService.emit('join-room', {
                        room: userAddToList.room.id,
                    });
                }

                return usersActions.assignUser({ user: new User(userAddToList), selectedUser: newSelectedUser });
            }),
        );
    });

    userAssignedRoomListener$ = createEffect(() => {
        return this.userAssignedEmitter$.pipe(
            concatLatestFrom(() => this.store.select(usersSelectors.selectCurrentUser)),
            filter(([{ user }, currentUser]) => {
                const isEmployee = currentUser?.id === user.id;

                return !isEmployee && !!user.room?.id;
            }),
            map(([{ user }]) => user.room!),
            map((room) => conversationActions.setRoom({ value: room })),
        );
    });

    userUnassignedListener$ = createEffect(() => {
        return this.userUnassignedEmitter$.pipe(
            concatLatestFrom(() => [this.store.select(usersSelectors.selectCurrentUser), this.store.select(usersSelectors.selectSelectedUser)]),
            map(([{ user, userUnassigned }, currentUser, selectedUser]) => {
                const isEmployee = currentUser?.id === user.id;
                const userRemoveFromList = isEmployee ? userUnassigned : user;

                if (isEmployee && userUnassigned.id === selectedUser?.id) {
                    this.router.navigate(['/chat/employee']).catch((err) => {
                        console.error(err);
                    });
                }
                return usersActions.unassignUser({ user: new User(userRemoveFromList) });
            }),
        );
    });

    currentUserListener$ = createEffect(() => {
        return this.currentUserEmitter$.pipe(
            map((user) => {
                return usersActions.currentUser({
                    value: user,
                });
            }),
        );
    });

    userSubsriptionActivatedListener$ = createEffect(() => {
        return this.userSubscriptionActivatedEmitter$.pipe(
            concatLatestFrom(() => this.store.select(usersSelectors.selectCurrentUser)),
            map(([userId, currentUser]) => {
                return currentUser && userId === currentUser?.id
                    ? authenticatedUserActions.getAuthenticatedUserWithoutLoader()
                    : usersActions.getUser({ userId });
            }),
        );
    });

    userVideoChangeListener$ = createEffect(() => {
        return this.userVideoChangeEmitter$.pipe(
            concatLatestFrom(() => this.store.select(usersSelectors.selectCurrentUser)),
            tap((e) => {
                console.log(e);
            }),
            filter(([, currentUser]) => !currentUser?.roles?.includes('ROLE_CLIENT')),
            map(([{ userId }]) => usersActions.getUser({ userId })),
        );
    });

    userSubsriptionInactivatedListener$ = createEffect(() => {
        return this.userSubscriptionInactivatedEmitter$.pipe(
            concatLatestFrom(() => this.store.select(usersSelectors.selectCurrentUser)),
            map(([userId, currentUser]) => {
                return currentUser && userId === currentUser?.id
                    ? authenticatedUserActions.getAuthenticatedUserWithoutLoader()
                    : usersActions.getUser({ userId });
            }),
        );
    });

    typingListener$ = createEffect(() => {
        return this.typingEmitter$.pipe(
            map((typing) => {
                return usersActions.typingUser({
                    value: typing,
                });
            }),
        );
    });

    userConnectedListener$ = createEffect(() => {
        return this.userConnectedEmitter$.pipe(
            map(({ userId, status }) => {
                return usersActions.setUserStatus({ userId, status });
            }),
        );
    });

    userStatusListener$ = createEffect(() => {
        return this.userStatusEmitter$.pipe(
            map(({ userId, status }) => {
                return usersActions.setUserStatus({ userId, status });
            }),
        );
    });

    /** Effects */

    // Get employee assigned to current user
    getUsers$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(usersActions.currentUser),
            filter((user) => user.value !== null && !!user.value.roles?.includes('ROLE_CLIENT')),
            mergeMap(() => {
                return fromEventPattern<UserDto[]>((handler) => {
                    this.socketService.emit('users', null, (result: { data: UserDto[] }) => {
                        handler(result.data);
                    });
                });
            }),
            map((data) => usersActions.putUsers({ value: data.map((userDto) => new User(userDto)) })),
        );
    });

    // Get list of users assigned to employee
    getUsersList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(usersActions.getUsers),
            throttleTime(250),
            concatLatestFrom(() => [
                this.store.select(usersSelectors.selectLastCount),
                this.store.select(usersSelectors.selectPage),
                this.store.select(usersSelectors.selectCanLoadMore),
            ]),
            map((data) => {
                return {
                    lastCount: data[1],
                    page: data[2],
                    canLoadMore: data[3],
                };
            }),
            filter((data) => data.canLoadMore),
            exhaustMap((data) => {
                return fromEventPattern<UserDto[]>((handler) => {
                    this.socketService.emit(
                        'users',
                        {
                            limit: USERS_LIMIT,
                            page: data.page,
                        },
                        (result: { data: UserDto[] }) => {
                            handler(result.data);
                        },
                    );
                }).pipe(take(1));
            }),
            map((data) => {
                return usersActions.putUsers({ value: data.map((userDto) => new User(userDto)) });
            }),
        );
    });

    setCurrentUserStatus$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(usersActions.setCurrentUserStatus),
                tap(({ status }) => {
                    // #FIXME
                    console.info('emit: change-status');
                    this.socketService.emit('change-status', { status: getStatusAsNumber(status) });
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    getUnreadMessagesCount$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(usersActions.putUsers),
            concatLatestFrom(() => [this.store.select(usersSelectors.selectCurrentUser)]),
            mergeMap((data) => {
                const users = data[0].value;
                const receiverId = data[1]?.id || '';
                const sendersIds = users.map((user) => user.id);

                return fromEventPattern<UnreadMessagesResponse[]>((handler) => {
                    this.socketService.socket.emit('unread-messages', { receiverId, sendersIds }, (result: UnreadMessagesResponse[]) => {
                        handler(result);
                    });
                }).pipe(take(1));
            }),
            map((data) => {
                return usersActions.putUnreadMessages({ value: data });
            }),
        );
    });

    getUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(usersActions.getUser),
            mergeMap(({ userId }) => {
                return fromEventPattern<User>((handler) => {
                    this.socketService.emit('get-user', { userId }, (userDto: UserDto) => {
                        handler(new User(userDto));
                    });
                });
            }),
            map((user) => {
                return usersActions.putUser({ value: user });
            }),
        );
    });

    readMessages$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(usersActions.readMessages),
            concatLatestFrom(() => [
                this.store.select(usersSelectors.selectCurrentUser),
                this.store.select(conversationSelectors.selectRoom),
                this.store.select(usersSelectors.selectSelectedUser),
            ]),
            filter((data) => !!data[1] && !!data[2] && !!data[3]),
            map((data) => {
                const currentUser = data[1]!;
                const room = data[2]!;
                const selectedUser = data[3]!;

                this.socketService.socket.emit('message-read', {
                    userId: currentUser.id,
                    room: room.id,
                    readAt: Date.now(),
                });

                return usersActions.setUnreadMessages({
                    value: {
                        userFromId: selectedUser.id,
                        unread: 0,
                    },
                });
            }),
        );
    });

    sendMessageSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(conversationActions.sendMessageSuccess),
            map(({ response }) => {
                if (Array.isArray(response)) {
                    return response.find((message) => message.type === 'HangoutsMessage');
                }

                return response.type === 'HangoutsMessage' ? response : undefined;
            }),
            filter((message) => message !== undefined),
            map((message) => message!),
            map((message) => {
                const userId = message.userToId;

                return usersActions.reduceUserHangouts({ userId });
            }),
        );
    });

    patchHangoutSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(hangoutActions.patchHangoutSuccessAction),
            map(({ userId, videoCount }) => {
                return usersActions.setUserHangouts({ userId, videoCount });
            }),
        );
    });

    constructor(
        private actions$: Actions,
        private socketService: SocketService,
        private store: Store,
        private router: Router,
    ) {}
}
