import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared';
import { AccountStoreModule } from '@app/store/account';
import { UserAvatarComponent } from './components/avatar/avatar.component';

@NgModule({
    imports: [CommonModule, RouterModule, SharedModule, AccountStoreModule],
    declarations: [UserAvatarComponent],
    exports: [UserAvatarComponent],
})
export class UserModule {}
