import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { MESSAGES_FEATURE_KEY } from './keys';
import { messagesReducer } from './reducers/messages.reducer';
import { MessagesEffects } from './effects/messages.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(MESSAGES_FEATURE_KEY, messagesReducer), EffectsModule.forFeature([MessagesEffects])],
})
export class MessagesStoreModule {}
