import { HttpErrorResponse } from '@angular/common/http';
import { CredentailsModel } from '@app/account/models';
import { createAction, props } from '@ngrx/store';
import { ErrorsModel, LoginModel, RegisterError, RegisterModel } from '../models';

export enum AccountActionTypes {
    REGISTER_ACTION = '[Account] Register action',
    REGISTER_SUCCESS_ACTION = '[Account] Register success action',
    REGISTER_ERROR_ACTION = '[Account] Register error action',
    LOGIN_ACTION = '[Account] Login action',
    LOGIN_SUCCESS_ACTION = '[Account] Login success action',
    LOGIN_ERROR_ACTION = '[Account] Login error action',
    LOGOUT_ACTION = '[Account] Logout action',
    LOGOUT_SUCCESS_ACTION = '[Account] Logout success action',
    LOGOUT_ERROR_ACTION = '[Account] Logout error action',
    REQUEST_RESET_PASSWORD_ACTION = '[Account] Request reset password action',
    REQUEST_RESET_PASSWORD_SUCCESS_ACTION = '[Account] Request reset password success action',
    REQUEST_RESET_PASSWORD_ERROR_ACTION = '[Account] Request reset password error action',
    RESET_PASSWORD_ACTION = '[Account] Reset password action',
    RESET_PASSWORD_SUCCESS_ACTION = '[Account] Reset password success action',
    RESET_PASSWORD_ERROR_ACTION = '[Account] Reset password error action',
    ACTIVATE_ACTION = '[Account] Activate action',
    ACTIVATE_SUCCESS_ACTION = '[Account] Activate success action',
    ACTIVATE_ERROR_ACTION = '[Account] Activate error action',
    CLEAR_ACTION = '[Account] Clear',
}

const registerAction = createAction(AccountActionTypes.REGISTER_ACTION, props<{ value: RegisterModel }>());
const registerSuccessAction = createAction(AccountActionTypes.REGISTER_SUCCESS_ACTION);
const registerErrorAction = createAction(AccountActionTypes.REGISTER_ERROR_ACTION, props<{ errors: RegisterError }>());
const loginAction = createAction(AccountActionTypes.LOGIN_ACTION, props<{ value: LoginModel }>());
const loginSuccessAction = createAction(AccountActionTypes.LOGIN_SUCCESS_ACTION, props<{ data: CredentailsModel }>());
const loginErrorAction = createAction(AccountActionTypes.LOGIN_ERROR_ACTION, props<{ response: HttpErrorResponse; email: string }>());
const logoutAction = createAction(AccountActionTypes.LOGOUT_ACTION);
const logoutSuccessAction = createAction(AccountActionTypes.LOGOUT_SUCCESS_ACTION);
const logoutErrorAction = createAction(AccountActionTypes.LOGOUT_ERROR_ACTION);
const requestResetPasswordAction = createAction(AccountActionTypes.REQUEST_RESET_PASSWORD_ACTION, props<{ email: string }>());
const requestResetPasswordSuccessAction = createAction(AccountActionTypes.REQUEST_RESET_PASSWORD_SUCCESS_ACTION);
const requestResetPasswordErrorAction = createAction(AccountActionTypes.REQUEST_RESET_PASSWORD_ERROR_ACTION, props<{ errors: ErrorsModel }>());
const resetPasswordAction = createAction(AccountActionTypes.RESET_PASSWORD_ACTION, props<{ email: string; token: string }>());
const resetPasswordSuccessAction = createAction(AccountActionTypes.RESET_PASSWORD_SUCCESS_ACTION);
const resetPasswordErrorAction = createAction(AccountActionTypes.RESET_PASSWORD_ERROR_ACTION, props<{ response: HttpErrorResponse | { data: ErrorsModel } }>());
const activateAction = createAction(AccountActionTypes.ACTIVATE_ACTION, props<{ token: string }>());
const activateSuccessAction = createAction(AccountActionTypes.ACTIVATE_SUCCESS_ACTION);
const activateErrorAction = createAction(AccountActionTypes.ACTIVATE_ERROR_ACTION, props<{ errors: ErrorsModel }>());
const clearAction = createAction(AccountActionTypes.CLEAR_ACTION);

export const accountActions = {
    registerAction,
    registerSuccessAction,
    registerErrorAction,
    loginAction,
    loginSuccessAction,
    loginErrorAction,
    logoutAction,
    logoutSuccessAction,
    logoutErrorAction,
    requestResetPasswordAction,
    requestResetPasswordSuccessAction,
    requestResetPasswordErrorAction,
    resetPasswordAction,
    resetPasswordSuccessAction,
    resetPasswordErrorAction,
    activateAction,
    activateSuccessAction,
    activateErrorAction,
    clearAction,
};
