import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { IconsModule } from './icons/icons.module';
import { ComponentsModule } from './components/components.module';
import { E2eSelectorModule } from './e2e-selector/e2e-selector.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule.forChild({
            extend: true,
        }),
        IconsModule,
        ComponentsModule,
        E2eSelectorModule,
    ],
    exports: [RouterModule, TranslateModule, IconsModule, ComponentsModule, E2eSelectorModule],
})
export class SharedModule {}
