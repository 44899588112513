import { Injectable } from '@angular/core';
import { CredentialsModel } from '@app/store/account/models/credentails.model';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class TokenService {
    constructor(private storageService: StorageService) {}

    setToken(credentials: CredentialsModel): void {
        this.storageService.setItem('credentials', credentials);
    }

    getToken(): string {
        return (<CredentialsModel>(<unknown>this.storageService.getItem('credentials')))?.token || '';
    }

    getRefreshToken(): string {
        return (<CredentialsModel>(<unknown>this.storageService.getItem('credentials')))?.refresh_token || '';
    }

    getRKey(): string {
        return (<CredentialsModel>(<unknown>this.storageService.getItem('credentials')))?.r_key || '';
    }

    removeToken(): void {
        this.storageService.removeItem('credentials');
    }
}
