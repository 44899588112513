import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { loaderReducer } from './store';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { LoaderService } from './service/loader.service';
import { LOADER_FEATURE_KEY } from './store/keys';

@NgModule({
    providers: [LoaderService],
    declarations: [SpinnerComponent],
    imports: [CommonModule, StoreModule.forFeature(LOADER_FEATURE_KEY, loaderReducer)],
    exports: [SpinnerComponent],
})
export class LoaderModule {}
