import { Injectable } from '@angular/core';
import { TimerService } from '@app/shared';
import { AuthenticatedUserFacade } from '../authenticated-user';
import { MessageFacade } from '../message';
import { MessagesFacade } from '../messages';
@Injectable({
    providedIn: 'root',
})
export class CleanupService {
    constructor(
        private authenticatedUserFacade: AuthenticatedUserFacade,
        private messagesFacade: MessagesFacade,
        private messageFacade: MessageFacade,
        private timerService: TimerService,
    ) {}

    cleanup(): void {
        this.timerService.stopTimer();
        this.authenticatedUserFacade.clear();
        this.messagesFacade.clear();
        this.messageFacade.clear();
    }
}
