import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { selectMessage, selectLoaded, selectLoading, selectSaved, selectSaving, selectDeleted, selectDeleting } from '../selectors/message.selector';
import { messageActions } from '../actions/message.actions';
import { MessageValueModel } from '../models';

@Injectable({
    providedIn: 'root',
})
export class MessageFacade {
    message$ = this.store.select(selectMessage).pipe(filter((message) => message !== null));
    loaded$ = this.store.select(selectLoaded).pipe(filter((loaded) => loaded !== null));
    loading$ = this.store.select(selectLoading).pipe(filter((loading) => loading !== null));
    saved$ = this.store.select(selectSaved).pipe(filter((saved) => saved !== null));
    saving$ = this.store.select(selectSaving).pipe(filter((saving) => saving !== null));
    deleted$ = this.store.select(selectDeleted).pipe(filter((deleted) => deleted !== null));
    deleting$ = this.store.select(selectDeleting).pipe(filter((deleting) => deleting !== null));

    constructor(private store: Store) {}

    getMessage(id: string): void {
        this.store.dispatch(messageActions.getMessageAction({ id }));
    }

    addMessage(value: MessageValueModel): void {
        this.store.dispatch(messageActions.addMessageAction({ value }));
    }

    updateMessage(id: string, value: MessageValueModel): void {
        this.store.dispatch(messageActions.updateMessageAction({ id, value }));
    }

    deleteMessage(id: string): void {
        this.store.dispatch(messageActions.deleteMessageAction({ id }));
    }

    clear(): void {
        this.store.dispatch(messageActions.clearAction());
    }
}
