import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthenticatedUserState } from '../reducers/authenticated-user.reducer';
import { AUTHENTICATED_USER_FEATURE_KEY } from '../keys';

const selectState = createFeatureSelector<AuthenticatedUserState>(AUTHENTICATED_USER_FEATURE_KEY);

export const selectLoaded = createSelector(selectState, (state: AuthenticatedUserState) => {
    return state.loaded;
});

export const selectUser = createSelector(selectState, (state: AuthenticatedUserState) => {
    return state.user;
});

export const selectErrors = createSelector(selectState, (state: AuthenticatedUserState) => {
    return state.errors;
});

export const selectUpdated = createSelector(selectState, (state: AuthenticatedUserState) => {
    return state.updated;
});

export const selectRequestedResetPassword = createSelector(selectState, (state: AuthenticatedUserState) => {
    return state.requestedResetPassword;
});

export const selectResetPassword = createSelector(selectState, (state: AuthenticatedUserState) => {
    return state.resetPassword;
});
