import { Injectable } from '@angular/core';
import { LoaderFacade } from '@app/store/loader';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { messagesActions } from '../actions/messages.actions';
import { HttpService } from '../services/http.service';

@Injectable()
export class MessagesEffects {
    getMessages$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(messagesActions.getMessagesAction),
            mergeMap((action) => {
                this.loaderFacade.add('get-messages');
                return this.httpService.getMessages(action.page, action.limit, action.sort, action.direction, action.term).pipe(
                    map((payload) => messagesActions.getMessagesSuccessAction({ items: payload.items, totalItems: payload.totalItems })),
                    catchError(() => of(messagesActions.getMessagesErrorAction())),
                );
            }),
        );
    });

    getMessagesSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(messagesActions.getMessagesSuccessAction),
                tap(() => this.loaderFacade.remove('get-messages')),
            );
        },
        {
            dispatch: false,
        },
    );

    getMessagesError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(messagesActions.getMessagesErrorAction),
                tap(() => this.loaderFacade.remove('get-messages')),
            );
        },
        {
            dispatch: false,
        },
    );

    constructor(private actions$: Actions, private httpService: HttpService, private loaderFacade: LoaderFacade) {}
}
