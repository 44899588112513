import { createAction, props } from '@ngrx/store';
import { MessageModel } from '../models';

enum MessagesActionTypes {
    GET_MESSAGES_ACTION = '[Messages] Get messages action',
    GET_MESSAGES_SUCCESS_ACTION = '[Messages] Get messages success action',
    GET_MESSAGES_ERROR_ACTION = '[Messages] Get messages error action',
    CLEAR_ACTION = '[Messages] Clear',
}

const getMessagesAction = createAction(
    MessagesActionTypes.GET_MESSAGES_ACTION,
    props<{ limit: number; page: number; sort: string | null; direction: string | null; term: string | null }>(),
);
const getMessagesSuccessAction = createAction(MessagesActionTypes.GET_MESSAGES_SUCCESS_ACTION, props<{ items: MessageModel[]; totalItems: number }>());
const getMessagesErrorAction = createAction(MessagesActionTypes.GET_MESSAGES_ERROR_ACTION);
const clearAction = createAction(MessagesActionTypes.CLEAR_ACTION);

export const messagesActions = {
    getMessagesAction,
    getMessagesSuccessAction,
    getMessagesErrorAction,
    clearAction,
};
