import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuDropdownComponent } from './menu/menu-dropdown.component';

@NgModule({
    imports: [CommonModule],
    declarations: [MenuDropdownComponent],
    exports: [MenuDropdownComponent],
})
export class MenuDropdownModule {}
