import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { USERS_FEATURE_KEY } from '@app/store/chat/users/keys';
import { usersReducer } from '@app/store/chat/users/reducers/users.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UsersEffects } from '@app/store/chat/users/effects/users.effects';

@NgModule({
    declarations: [],
    imports: [CommonModule, StoreModule.forFeature(USERS_FEATURE_KEY, usersReducer), EffectsModule.forFeature([UsersEffects])],
})
export class UsersStoreModule {}
