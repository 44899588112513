import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoaderState } from '../reducers/loader.reducer';
import { LOADER_FEATURE_KEY } from '../keys';

export const selectLoaderState = createFeatureSelector<LoaderState>(LOADER_FEATURE_KEY);

export const selectLoading = createSelector(selectLoaderState, (state: LoaderState) => {
    return state.loading;
});

export const selectIds = createSelector(selectLoaderState, (state: LoaderState) => {
    return state.ids;
});
