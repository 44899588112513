import { Observable, Observer } from 'rxjs';
import { FileModel } from '../models';

export const FileToString = (file: File, types: string[] = []): Observable<FileModel> => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    return new Observable((observer: Observer<FileModel>) => {
        fileReader.onloadend = () => {
            if (types.length > 0 && types.includes(file.type)) {
                observer.next({
                    name: file.name,
                    type: file.type,
                    size: file.size,
                    value: <string>fileReader.result,
                });
            } else {
                observer.error({
                    type: 'UNSUPPORTED_TYPE',
                });
            }
            observer.complete();
        };
    });
};
