import { Observable } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';

import * as plPL from '../../../translations/pl-PL.json';

export class TranslateLoaderStatic implements TranslateLoader {
    getTranslation(lang: string): Observable<{ [key: string]: string }> {
        return new Observable((observer) => {
            switch (lang) {
                case 'pl-PL':
                    observer.next(<{ [key: string]: string }>plPL);
                    break;
            }
            observer.complete();
        });
    }
}
