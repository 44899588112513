import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs/operators';

import {
    selectErrors,
    selectLoaded,
    selectRequestedResetPassword,
    selectResetPassword,
    selectUpdated,
    selectUser,
} from '../selectors/authenticated-user.selector';
import { authenticatedUserActions } from '../actions/authenticated-user.actions';
import { ErrorsModel } from '@app/store/account/models';
import { User } from '@app/shared/models/user.model';

@Injectable({
    providedIn: 'root',
})
export class AuthenticatedUserFacade {
    loaded$ = this.store.select(selectLoaded);
    user$ = this.store.select(selectUser).pipe(filter((data): data is User => data !== null));
    errors$ = this.store.select(selectErrors).pipe(filter((data): data is ErrorsModel => data !== null));
    updated$ = this.store.select(selectUpdated).pipe(filter((data): data is boolean => data !== null));
    requestedResetPassword$ = this.store.select(selectRequestedResetPassword).pipe(filter((data): data is boolean => data !== null));
    resetPassword$ = this.store.select(selectResetPassword).pipe(filter((data): data is boolean => data !== null));
    constructor(private store: Store) {}

    get user(): User {
        let user = {} as User;
        this.user$
            .pipe(
                take(1),
                tap((value) => {
                    if (value) {
                        user = value;
                    }
                }),
            )
            .subscribe();
        return user;
    }

    loadUser(): void {
        this.store.dispatch(authenticatedUserActions.getAuthenticatedUser());
    }

    loadUserGracefull(): void {
        this.store.dispatch(authenticatedUserActions.getAuthenticatedUserGracefull());
    }

    clear(): void {
        this.store.dispatch(authenticatedUserActions.clear());
    }

    updateUser(firstName: string, lastName: string, phoneNumber: string): void {
        this.store.dispatch(authenticatedUserActions.updateAuthenticatedUser({ firstName, lastName, phoneNumber }));
    }

    requestResetPassword(email: string): void {
        this.store.dispatch(authenticatedUserActions.requestResetPassword({ email }));
    }

    resetPassword(email: string, token: string): void {
        this.store.dispatch(authenticatedUserActions.resetPassword({ email, token }));
    }
}
