import { createReducer, on } from '@ngrx/store';
import { messagesActions } from '../actions/messages.actions';
import { MessageModel } from '../models';
import { ErrorsModel } from '../models/errors.model';

export interface MessagesState {
    messages: MessageModel[] | null;
    loaded: boolean | null;
    loading: boolean | null;
    errors: ErrorsModel | null;
    pagination: {
        totalItems: number;
    };
}

export const initialState: MessagesState = {
    messages: null,
    loaded: null,
    loading: null,
    errors: null,
    pagination: {
        totalItems: 0,
    },
};

export const messagesReducer = createReducer(
    initialState,
    on(
        messagesActions.getMessagesAction,
        (state): MessagesState => ({
            ...state,
            loading: true,
        }),
    ),
    on(
        messagesActions.getMessagesSuccessAction,
        (state, payload): MessagesState => ({
            ...state,
            loaded: true,
            loading: false,
            messages: payload.items,
            pagination: {
                ...state.pagination,
                totalItems: payload.totalItems,
            },
        }),
    ),
    on(
        messagesActions.getMessagesErrorAction,
        (state): MessagesState => ({
            ...state,
            loading: false,
        }),
    ),

    on(
        messagesActions.clearAction,
        (state): MessagesState => ({
            ...state,
            ...initialState,
        }),
    ),
);
