import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class TemplatePageTitleStrategy extends TitleStrategy {
    private readonly TITLE_PREFIX = environment.titlePrefix ?? 'WeTalk';

    constructor(private readonly title: Title, private translateService: TranslateService) {
        super();
    }

    override updateTitle(routerState: RouterStateSnapshot) {
        const titleKey = this.buildTitle(routerState);
        const title = titleKey ? `${this.TITLE_PREFIX} | ${this.translateService.instant(titleKey) as string}` : this.TITLE_PREFIX;
        this.title.setTitle(title);
    }
}
