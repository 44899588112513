import { Component, OnInit, Renderer2 } from '@angular/core';
import { WindowService } from '@app/shared';

@Component({
    selector: 'app-shell',
    templateUrl: './shell.component.html',
    styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit {
    constructor(private windowService: WindowService, private renderer2: Renderer2) {}

    ngOnInit(): void {
        this.renderer2.removeStyle(this.windowService.document.body, 'background-color');
        this.renderer2.removeStyle(this.windowService.documentElement, 'background-color');
    }
}
