import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from '@angular/core';
import { AlertsService } from '../../services/alerts.service';
import { AlertModel } from '../../models/alert.model';
import { distinctUntilChanged, filter, map, Observable } from 'rxjs';
import { ScrollToFocusService } from '@app/shared/services';

@Component({
    selector: 'app-alert',
    styleUrls: ['./alert.component.scss'],
    templateUrl: './alert.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent implements OnInit, AfterViewInit {
    @Input()
    alert!: AlertModel;

    @Input()
    last!: boolean;

    progeress$!: Observable<number>;

    constructor(private alertsService: AlertsService, private scrollToFocusService: ScrollToFocusService, private elementRef: ElementRef) {}

    get icon(): string {
        switch (this.alert.severity) {
            case 'success':
                return 'check-circle-fill';
            case 'danger':
                return 'exclamation-circle';
            default:
                return 'info-circle';
        }
    }

    ngOnInit(): void {
        this.alertsService.shown(this.alert.id);
        this.progeress$ = this.alertsService.progresses$.pipe(
            map((alerts) => {
                return alerts[this.alert.id];
            }),
            distinctUntilChanged(),
            filter((progress) => progress !== undefined),
            map((progress) => 62.8 * (Math.floor(progress * 100) / 100)),
        );
    }

    ngAfterViewInit(): void {
        const rect = (<HTMLElement>this.elementRef.nativeElement).getBoundingClientRect();
        if (rect.top < 0) {
            const top = (<HTMLElement>(<HTMLElement>this.elementRef.nativeElement).firstChild).getBoundingClientRect().y + window.scrollY - 10;
            this.scrollToFocusService.top(top, 2);
        }
    }

    onCloseClick(event: MouseEvent) {
        event.preventDefault();
        this.alertsService.hide(this.alert.id);
    }

    onMouseEnter() {
        this.alertsService.onMouseEnter(this.alert.id);
    }

    onMouseLeave() {
        this.alertsService.onMouseLeave(this.alert.id);
    }
}
