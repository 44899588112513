import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PlansState } from '../reducers/plans.reducer';
import { PLANS_FEATURE_KEYS } from '../keys';

const selectState = createFeatureSelector<PlansState>(PLANS_FEATURE_KEYS);

export const selectPlans = createSelector(selectState, (state: PlansState) => {
    return state.plans;
});

export const selectLoaded = createSelector(selectState, (state: PlansState) => {
    return state.loaded;
});

export const selectLoading = createSelector(selectState, (state: PlansState) => {
    return state.loading;
});
