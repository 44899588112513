import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IconsModule } from '../icons/icons.module';
import { AlertsComponent } from './components/alerts/alerts.component';
import { AlertComponent } from './components/alert/alert.component';
import { AlertActionComponent } from './components/action/action.component';

@NgModule({
    imports: [CommonModule, TranslateModule, IconsModule],
    declarations: [AlertsComponent, AlertComponent, AlertActionComponent],
    exports: [AlertsComponent],
})
export class AlertsModule {}
