import { Injectable } from '@angular/core';
import { UrlTree, ActivatedRouteSnapshot, Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { PrivilegesService } from '../services/privileges.service';

@Injectable({
    providedIn: 'root',
})
export class HasPrivilegeGuard  {
    constructor(private privilegesService: PrivilegesService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        const feature = <string>route.data['access'];
        return this.privilegesService.has$(feature).pipe(map((hasAccess) => (hasAccess ? true : this.router.createUrlTree(['/home']))));
    }
}
