import { Injectable } from '@angular/core';
import { BehaviorSubject, debounceTime, delay, filter, Subject, tap } from 'rxjs';
import { WindowService } from '../window/window.service';

@Injectable({
    providedIn: 'root',
})
export class ScrollToFocusService {
    run$ = new Subject();
    check$ = this.run$.pipe(delay(50));

    private top$ = new BehaviorSubject<{ top: number; priority: number } | null>(null);

    constructor(private windowService: WindowService) {
        this.top$
            .pipe(
                filter((value): value is { top: number; priority: number } => value !== null),
                debounceTime(50),
                tap((value) => {
                    this.windowService.scrollTop(value.top);
                    this.top$.next(null);
                }),
            )
            .subscribe();
    }

    check(): void {
        this.run$.next(true);
    }

    top(top: number, priority = 1) {
        if (this.top$.value === null) {
            this.top$.next({ top, priority });
        } else if (priority < this.top$.value.priority) {
            this.top$.next({ top, priority });
        } else if (top < this.top$.value.top) {
            this.top$.next({ top, priority });
        } else {
            this.top$.next(this.top$.value);
        }
    }
}
