import { HttpErrorResponse } from '@angular/common/http';
import { createReducer, on } from '@ngrx/store';
import { accountActions } from '../actions/account.actions';
import { ErrorsModel } from '../models';

export interface AccountState {
    saving: boolean | null;
    registered: boolean | null;
    logged: boolean | null;
    loggedOut: boolean | null;
    requestedResetPassword: boolean | null;
    resetPassword: boolean | null;
    activated: boolean | null;
    errors: ErrorsModel | null;
}

export const initialState: AccountState = {
    saving: null,
    registered: null,
    logged: null,
    loggedOut: null,
    requestedResetPassword: null,
    resetPassword: null,
    activated: null,
    errors: null,
};

export const accountReducer = createReducer(
    initialState,
    // register
    on(
        accountActions.registerAction,
        (state): AccountState => ({
            ...state,
            saving: true,
            registered: false,
            errors: null,
        }),
    ),
    on(
        accountActions.registerSuccessAction,
        (state): AccountState => ({
            ...state,
            saving: false,
            registered: true,
        }),
    ),
    on(
        accountActions.registerErrorAction,
        (state): AccountState => ({
            ...state,
            saving: false,
            registered: false,
        }),
    ),
    // login
    on(
        accountActions.loginAction,
        (state): AccountState => ({
            ...state,
            saving: true,
            logged: false,
            errors: null,
        }),
    ),
    on(
        accountActions.loginSuccessAction,
        (state): AccountState => ({
            ...state,
            saving: false,
            logged: true,
        }),
    ),
    on(
        accountActions.loginErrorAction,
        (state): AccountState => ({
            ...state,
            saving: false,
            logged: false,
        }),
    ),
    // logout
    on(
        accountActions.logoutAction,
        (state): AccountState => ({
            ...state,
            saving: true,
            errors: null,
        }),
    ),
    on(
        accountActions.logoutSuccessAction,
        (state): AccountState => ({
            ...state,
            saving: false,
            loggedOut: true,
        }),
    ),
    on(
        accountActions.logoutErrorAction,
        (state): AccountState => ({
            ...state,
            saving: false,
            loggedOut: false,
        }),
    ),
    // request reset password
    on(
        accountActions.requestResetPasswordAction,
        (state): AccountState => ({
            ...state,
            saving: true,
            requestedResetPassword: false,
            errors: null,
        }),
    ),
    on(
        accountActions.requestResetPasswordSuccessAction,
        (state): AccountState => ({
            ...state,
            saving: false,
            requestedResetPassword: true,
        }),
    ),
    on(
        accountActions.requestResetPasswordErrorAction,
        (state): AccountState => ({
            ...state,
            saving: false,
            requestedResetPassword: false,
        }),
    ),
    // reset password
    on(
        accountActions.resetPasswordAction,
        (state): AccountState => ({
            ...state,
            saving: true,
            resetPassword: false,
            errors: null,
        }),
    ),
    on(
        accountActions.resetPasswordSuccessAction,
        (state): AccountState => ({
            ...state,
            saving: false,
            resetPassword: true,
            errors: null,
        }),
    ),
    on(accountActions.resetPasswordErrorAction, (state, action): AccountState => {
        if (!(action.response as HttpErrorResponse)?.status) {
            return {
                ...state,
                saving: false,
                resetPassword: false,
                errors: (action.response as { data: ErrorsModel }).data,
            };
        }
        return {
            ...state,
            saving: false,
            resetPassword: false,
            errors: null,
        };
    }),
    // activate
    on(
        accountActions.activateAction,
        (state): AccountState => ({
            ...state,
            saving: true,
            activated: null,
            errors: null,
        }),
    ),
    on(
        accountActions.activateSuccessAction,
        (state): AccountState => ({
            ...state,
            saving: false,
            activated: true,
        }),
    ),
    on(
        accountActions.activateErrorAction,
        (state): AccountState => ({
            ...state,
            saving: false,
            activated: false,
        }),
    ),
    // clear
    on(
        accountActions.clearAction,
        (state): AccountState => ({
            ...state,
            ...initialState,
        }),
    ),
);
