<div class="d-flex align-items-center alert alert-{{ alert.severity }}" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
    <div class="icon icon-7 icon-white me-8" [appIcon]="icon"></div>
    <div class="lh-1">{{ alert.message }}</div>
    <app-action *ngIf="alert.action" [action]="alert.action" [id]="alert.id"></app-action>
    <div class="circle ms-auto pointer" (click)="onCloseClick($event)">
        <div class="icon icon-white icon-6" [appIcon]="'x'"></div>
        <svg class="progress-circle" [attr.height]="24" [attr.width]="24">
            <circle
                [attr.stroke-width]="1.5"
                [attr.transform]="'rotate(-90, 12, 12)'"
                [attr.fill]="'transparent'"
                [attr.r]="10"
                [attr.cx]="12"
                [attr.cy]="12"
                [attr.stroke-dasharray]="62.8"
                [style.strokeDashoffset]="progeress$ | async"
            />
        </svg>
    </div>
</div>
