import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MessageState } from '../reducers/message.reducer';
import { MESSAGE_FEATURE_KEY } from '../keys';

const selectState = createFeatureSelector<MessageState>(MESSAGE_FEATURE_KEY);

export const selectMessage = createSelector(selectState, (state: MessageState) => {
    return state.message;
});

export const selectLoaded = createSelector(selectState, (state: MessageState) => {
    return state.loaded;
});

export const selectLoading = createSelector(selectState, (state: MessageState) => {
    return state.loading;
});

export const selectSaved = createSelector(selectState, (state: MessageState) => {
    return state.saved;
});

export const selectSaving = createSelector(selectState, (state: MessageState) => {
    return state.saving;
});

export const selectDeleted = createSelector(selectState, (state: MessageState) => {
    return state.deleted;
});

export const selectDeleting = createSelector(selectState, (state: MessageState) => {
    return state.deleting;
});
