import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { MESSAGE_FEATURE_KEY } from './keys';
import { messageReducer } from './reducers/message.reducer';
import { MessageEffects } from './effects/message.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(MESSAGE_FEATURE_KEY, messageReducer), EffectsModule.forFeature([MessageEffects])],
})
export class MessageStoreModule {}
