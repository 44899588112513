import { HttpErrorResponse } from '@angular/common/http';
import { ErrorsModel } from '@app/store/account/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { User } from '@app/shared/models/user.model';

export const authenticatedUserActions = createActionGroup({
    source: 'Authenticated User',
    events: {
        'Get Authenticated User Without Loader': emptyProps(),
        'Get Authenticated User': emptyProps(),
        'Get Authenticated User Success': props<{ user: User }>(),
        'Get Authenticated User Error': emptyProps(),

        'Get Authenticated User Gracefull': emptyProps(),
        'Get Authenticated User Gracefull Success': props<{ user: User }>(),
        'Get Authenticated User Gracefull Error': emptyProps(),

        'Update Authenticated User': props<{ firstName: string; lastName: string; phoneNumber: string }>(),
        'Update Authenticated User Success': emptyProps(),
        'Update Authenticated User Error': props<{ errors: ErrorsModel }>(),

        'Request Reset Password': props<{ email: string }>(),
        'Request Reset Password Success': emptyProps(),
        'Request Reset Password Error': props<{ errors: ErrorsModel }>(),

        'Reset Password': props<{ email: string; token: string }>(),
        'Reset Password Success': emptyProps(),
        'Reset Password Error': props<{ response: HttpErrorResponse | { data: ErrorsModel } }>(),

        Clear: emptyProps(),
    },
});
