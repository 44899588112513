import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@app/shared/models';
import { map, Observable } from 'rxjs';
import { UserDto } from '@app/shared/models/user.model';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private httpClient: HttpClient) {}

    getUser(): Observable<UserDto> {
        return this.httpClient
            .addServer()
            .get<ApiResponse<UserDto>>(`api/v1/user/data/own`)
            .pipe(map((response) => response.data));
    }

    getUserGracefull(): Observable<UserDto> {
        return this.httpClient
            .addServer()
            .skipRefreshToken()
            .addRefreshTokenGracefull()
            .get<ApiResponse<UserDto>>(`api/v1/user/data/own`)
            .pipe(map((response) => response.data));
    }

    updateUser(firstName: string, lastName: string, phoneNumber: string): Observable<UserDto> {
        const data = {
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
        };

        return this.httpClient
            .addServer()
            .put<ApiResponse<UserDto>>(`api/v1/user`, data)
            .pipe(map((response) => response.data));
    }

    requestResetPassword(email: string): Observable<unknown> {
        const params = { email: email.trim().toLocaleLowerCase() };
        return this.httpClient.addServer().skipRefreshToken().skipErrorHandler().post<ApiResponse<unknown>>(`api/v1/public/send_reset`, params);
    }

    resetPassword(password: string, token: string): Observable<unknown> {
        const params = { password, token };
        return this.httpClient
            .addServer()
            .skipRefreshToken()
            .skipErrorHandler()
            .post<ApiResponse<unknown>>(`api/v1/public/reset`, params)
            .pipe(
                map((response) => {
                    if (response.success === false) {
                        throw response;
                    }
                    return response;
                }),
                map((response) => response),
            );
    }
}
