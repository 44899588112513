import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { conversationActions } from '@app/store/chat/conversation/actions/conversation.actions';
import { conversationSelectors } from '@app/store/chat/conversation/selectors/conversation.selector';
import { SendMessageType } from '../models/send-message.model';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
    providedIn: 'root',
})
export class ConversationFacade {
    room$ = this.store.select(conversationSelectors.selectRoom);
    messages$ = this.store.select(conversationSelectors.selectMessages);
    loading$ = this.store.select(conversationSelectors.selectLoading);
    hasMoreMessages$ = this.store.select(conversationSelectors.selectHasMoreMessages);
    messagesLoaded$ = this.store.select(conversationSelectors.selectMessagesLoaded);
    messageSend$ = this.store.select(conversationSelectors.selectMessageSend);

    constructor(private store: Store) {}

    reset() {
        this.store.dispatch(conversationActions.reset());
    }

    clearRoom() {
        this.store.dispatch(
            conversationActions.setRoom({
                value: null,
            }),
        );
    }

    selectRoom(user_id: string) {
        this.store.dispatch(conversationActions.selectRoom({ value: user_id }));
    }

    sendMessage(
        messageType: SendMessageType,
        payload: Record<string, any> | null,
        callbacks?: {
            success?: () => void;
            error?: () => void;
        },
    ) {
        this.store.dispatch(conversationActions.sendMessage({ messageType, payload: payload ?? {}, callbacks, uuid: uuidv4() }));
    }

    nextPage() {
        this.store.dispatch(conversationActions.nextPage());
    }

    saveVoiceMessage(
        blob: Blob,
        callbacks?: {
            success?: () => void;
            error?: () => void;
        },
    ) {
        this.store.dispatch(conversationActions.saveVoiceMessage({ blob, uuid: uuidv4(), callbacks }));
    }

    emitTyping() {
        this.store.dispatch(conversationActions.typing());
    }
}
