<div class="menu-dropdown-wrapper" #menuDropdown (mouseenter)="onEnter()" (mouseleave)="onLeave()">
    <div #hoverItem class="h-100 fit-content" (click)="onClick()">
        <ng-content></ng-content>
    </div>
    <div
        class="menu-dropdown"
        [class]="{ 'w-100': (sm$ | async) === false }"
        #menuItem
        [style.top.px]="top"
        [style.left.px]="(sm$ | async) ? left : 0"
        (mouseenter)="onMenuEnter()"
        (mouseleave)="onMenuLeave()"
    >
        <ul (click)="onMenuItemClick()" class="unstyled">
            <ng-content select="[dropdownItems]"></ng-content>
        </ul>
    </div>
</div>
