import { createAction, props } from '@ngrx/store';
import { FormType, ProfileModel, ProfileValueModel } from '../models';

enum ProfileActionTypes {
    GET_PROFILE_ACTION = '[Profile] Get profile action',
    GET_PROFILE_SUCCESS_ACTION = '[Profile] Get profile success action',
    GET_PROFILE_ERROR_ACTION = '[Profile] Get profile error action',
    EDIT_PROFILE_ACTION = '[Profile] Edit profile action',
    EDIT_PROFILE_SUCCESS_ACTION = '[Profile] Edit profile success action',
    EDIT_PROFILE_ERROR_ACTION = '[Profile] Edit profile error action',
    DELETE_PROFILE_ACTION = '[Profile] Delete profile action',
    DELETE_PROFILE_SUCCESS_ACTION = '[Profile] Delete profile success action',
    DELETE_PROFILE_ERROR_ACTION = '[Profile] Delete profile error action',
    CLEAR_ACTION = '[Profile] Clear',
}

const getProfileAction = createAction(ProfileActionTypes.GET_PROFILE_ACTION, props<{ id?: string }>());
const getProfileSuccessAction = createAction(ProfileActionTypes.GET_PROFILE_SUCCESS_ACTION, props<{ profile: ProfileModel }>());
const getProfileErrorAction = createAction(ProfileActionTypes.GET_PROFILE_ERROR_ACTION);
const editProfileAction = createAction(ProfileActionTypes.EDIT_PROFILE_ACTION, props<{ id: string; value: ProfileValueModel; formType: FormType }>());
const editProfileSuccessAction = createAction(ProfileActionTypes.EDIT_PROFILE_SUCCESS_ACTION, props<{ emailChanged?: boolean }>());
const editProfileErrorAction = createAction(ProfileActionTypes.EDIT_PROFILE_ERROR_ACTION);
const deleteProfileAction = createAction(ProfileActionTypes.DELETE_PROFILE_ACTION);
const deleteProfileSuccessAction = createAction(ProfileActionTypes.DELETE_PROFILE_SUCCESS_ACTION);
const deleteProfileErrorAction = createAction(ProfileActionTypes.DELETE_PROFILE_ERROR_ACTION, props<{ message?: string }>());
const clearAction = createAction(ProfileActionTypes.CLEAR_ACTION);

export const profileActions = {
    getProfileAction,
    getProfileSuccessAction,
    getProfileErrorAction,
    editProfileAction,
    editProfileSuccessAction,
    editProfileErrorAction,
    deleteProfileAction,
    deleteProfileSuccessAction,
    deleteProfileErrorAction,
    clearAction,
};
