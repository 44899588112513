import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { PLANS_FEATURE_KEYS } from './keys';
import { plansReducer } from './reducers/plans.reducer';
import { PlansEffects } from './effects/plans.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(PLANS_FEATURE_KEYS, plansReducer), EffectsModule.forFeature([PlansEffects])],
})
export class PlansStoreModule {}
