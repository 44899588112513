<header class="bg-secondary shadow-1 px-8 d-flex align-items-center">
    <a [routerLink]="(atLeastEmployee$ | async) ? '/chat/employee' : '/chat/user'" class="me-auto">
        <img class="logo pb-3 pt-5" src="assets/images-optimized/logo-wetalk-nospace.png" />
    </a>
    <app-timer></app-timer>

    <app-menu-dropdown position="right">
        <div *ngIf="sm$ | async; else mobileVersion" class="d-flex align-items-center align-self-stretch ps-12 border-start border-white h-100">
            <div class="d-flex align-items-center">
                <a class="avatar" [routerLink]="['/profile']">
                    <app-user-avatar></app-user-avatar>
                </a>
                <div *ngIf="user$ | async as user" class="ms-8 fs-14 fw-600 c-default">
                    {{ 'avatar.hello-name' | translate: { name: username$ | async } }}
                </div>
                <div class="icon icon-8 rotate-90 ms-4" appIcon="arrow"></div>
            </div>
        </div>

        <ng-template #mobileVersion>
            <svg class="burger-menu"><use xlink:href="assets/svgs/icons.svg#list"></use></svg>
        </ng-template>

        <ng-container dropdownItems>
            <li>
                <a [routerLink]="['/profile']">{{ 'header-menu.dropdown.item.my-account' | translate }}</a>
            </li>
            <li *ngFor="let menuItem of menu$ | async">
                <ng-container [ngTemplateOutlet]="item" [ngTemplateOutletContext]="{ menuItem: menuItem }"></ng-container>
            </li>
            <li>
                <a [routerLink]="(atLeastEmployee$ | async) ? '/chat/employee' : '/chat/user'">
                    {{ 'admin.menu.item.chat' | translate }}
                </a>
            </li>
            <li>
                <a class="d-flex align-items-center text-danger" (click)="logout()">
                    <span class="me-auto">{{ 'header-menu.dropdown.item.logout' | translate }}</span>
                    <div class="icon icon-5 icon-danger ms-5" appIcon="box-arrow-left"></div>
                </a>
            </li>
        </ng-container>
    </app-menu-dropdown>
</header>

<ng-template #item let-item="menuItem">
    <a [routerLink]="[item.path]">{{ item.title | translate }}</a>
</ng-template>
