import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CredentailsModel } from '@app/account/models';
import { TokenService } from '@app/shared';
import { share, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(private httpClient: HttpClient, private tokenService: TokenService) {}

    refresh() {
        const headers = new HttpHeaders({
            'R-Key': this.tokenService.getRKey(),
        });

        return this.httpClient
            .addServer()
            .skipToken()
            .skipRefreshToken()
            .skipErrorHandler()
            .post<CredentailsModel>(
                'api/v1/token/refresh',
                {
                    refresh_token: this.tokenService.getRefreshToken(),
                },
                {
                    headers,
                    responseType: 'json',
                    observe: 'response',
                },
            )
            .pipe(
                tap((response) => {
                    const r_key = response.headers.get('R-Key');
                    const credentails = { ...response.body, r_key } as CredentailsModel;

                    this.tokenService.setToken(credentails);
                }),
                share(),
            );
    }
}
