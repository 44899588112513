import { Injectable } from '@angular/core';
import { AuthenticatedUserFacade } from '@app/store/authenticated-user';
import { map, Observable } from 'rxjs';
import { Role } from '@app/privileges/enum/roles.enum';

const ROLE_USER: string[] = [''];
const ROLE_EMPLOYEE: string[] = [...ROLE_USER, 'psychologist_chat'];
const ROLE_SERVICE_ADMIN: string[] = ['admin-panel', 'users_read', 'users_write', 'users_read', 'psychologist_read', 'hangouts', ...ROLE_EMPLOYEE];
const ROLE_ADMIN: string[] = [
    'psychologist_write',
    'system_messages',
    'payments',
    'polls',
    'discount_codes',
    'welcome_messages',
    'welcome_videos',
    ...ROLE_SERVICE_ADMIN,
];

@Injectable({
    providedIn: 'root',
})
export class PrivilegesService {
    privileges$ = this.authenticatedUserFacade.user$.pipe(
        map((user) => user.roles),
        map((roles) => {
            if (roles?.includes('ROLE_ADMIN')) {
                return [...ROLE_ADMIN, 'role_admin', 'edit_profile'];
            } else if (roles?.includes('ROLE_SERVICE_ADMIN')) {
                return [...ROLE_SERVICE_ADMIN, 'role_service_admin', 'edit_profile'];
            } else if (roles?.includes('ROLE_EMPLOYEE')) {
                return [...ROLE_EMPLOYEE, 'psychologist_chat'];
            } else {
                return [...ROLE_USER, 'user_chat', 'edit_profile'];
            }
        }),
    );

    constructor(private authenticatedUserFacade: AuthenticatedUserFacade) {}

    has$(access: string): Observable<boolean> {
        return this.privileges$.pipe(map((privilges) => privilges.includes(access)));
    }

    hasNot$(access: string): Observable<boolean> {
        return this.privileges$.pipe(map((privilges) => !privilges.includes(access)));
    }

    hasAtLeast$(atLeastRole: Role) {
        const roles = Object.values(Role);
        const validRoles: Role[] = [];

        for (let i = 0; roles.length > i; i++) {
            const role = roles[i];
            validRoles.push(role);

            if (role === atLeastRole) {
                break;
            }
        }

        return this.authenticatedUserFacade.user$.pipe(
            map((user) => user.roles),
            map((roles) => roles?.some((role) => validRoles.includes(role as Role))),
        );
    }
}
