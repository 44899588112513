import { Component } from '@angular/core';
import { environment } from '@env/environment';
import { build } from '../../../../build';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
    isProd = environment.production;
    version = <{ tag: string; time: string }>build;
}
