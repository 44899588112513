import { Component, OnDestroy } from '@angular/core';
import { AuthenticatedUserFacade } from '@app/store/authenticated-user';
import { map, Subscription } from 'rxjs';

@Component({
    selector: 'app-user-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
})
export class UserAvatarComponent implements OnDestroy {
    avatar$ = this.authenticatedUserFacade.user$.pipe(map((user) => user.avatar || 'assets/images-optimized/avatar.png'));

    private subscriptions$ = new Subscription();

    constructor(private authenticatedUserFacade: AuthenticatedUserFacade) {}

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }
}
