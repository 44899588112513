import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { LoaderActions } from '../actions/loader.actions';
import { selectLoading, selectIds } from '../selectors/loading.selectors';

@Injectable({
    providedIn: 'root',
})
export class LoaderFacade {
    constructor(private store: Store) {}

    add(id: string): void {
        this.store.dispatch(LoaderActions.showAction({ id }));
    }

    remove(id: string): void {
        this.store.dispatch(LoaderActions.hideAction({ id }));
    }

    clear(): void {
        this.store.dispatch(LoaderActions.claerAction());
    }

    isLoading$(): Observable<boolean> {
        return this.store.select(selectLoading).pipe(distinctUntilChanged());
    }

    ids$(): Observable<string[]> {
        return this.store.select(selectIds).pipe(distinctUntilChanged());
    }
}
