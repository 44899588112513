import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Message, Room } from '@app/store/chat/conversation';
import { MessageValue } from '../models/send-message.model';

export const conversationActions = createActionGroup({
    source: 'Chat Conversation',
    events: {
        Reset: emptyProps(),
        'Select room': props<{ value: string }>(),
        'Set room': props<{ value: Room | null }>(),
        'Set messages': props<{ value: Message[] }>(),
        'Put messages': props<{ value: Message[] }>(),
        'Put message': props<{ value: Message }>(),
        'Send message': props<MessageValue>(),
        'Send message success': props<{ response: Message | Message[]; uuid: string }>(),
        'Send message error': props<{ uuid: string }>(),
        'Set page': props<{ value: number }>(),
        'Next page': emptyProps(),
        'Save voice message': props<{
            blob: Blob;
            uuid: string;
            callbacks?: {
                success?: () => void;
                error?: () => void;
            };
        }>(),
        'Save voice message error': emptyProps(),
        Typing: emptyProps(),
        'New message': props<{ value: Message }>(),
    },
});
