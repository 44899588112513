import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { SocketService } from '@app/shared/services/socket.service';

export function websocketDisconnectGuard(): CanDeactivateFn<unknown> {
    return () => {
        const socketService = inject(SocketService);
        socketService.socket.disconnect();

        return true;
    };
}
