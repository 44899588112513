import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { I18nModule, TranslateLoaderStatic } from './i18n';
import { ShellModule } from './shell/shell.module';
import { AuthenticatedUserStoreModule } from './store/authenticated-user';

import { environment } from '../environments/environment';
import { HttpService } from './core/http';
import { AppComponent } from './app.component';
import { LoaderModule } from './store/loader';
import { AlertsModule } from './shared/alerts';
import { registerLocaleData } from '@angular/common';
import localePL from '@angular/common/locales/pl';

registerLocaleData(localePL);

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ShellModule,
        LoaderModule,
        AuthenticatedUserStoreModule,
        I18nModule,
        AlertsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: () => {
                    return new TranslateLoaderStatic();
                },
                deps: [],
            },
            defaultLanguage: environment.defaultLanguage,
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.sw,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
        StoreModule.forRoot({}, {}),
        EffectsModule.forRoot([]),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
        AppRoutingModule,
    ],
    declarations: [AppComponent],
    providers: [
        {
            provide: HttpClient,
            useClass: HttpService,
        },
        { provide: LOCALE_ID, useValue: 'pl' },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
