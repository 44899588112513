import { HttpErrorResponse } from '@angular/common/http';
import { ErrorsModel } from '@app/store/account/models';
import { createReducer, on } from '@ngrx/store';
import { authenticatedUserActions } from '../actions/authenticated-user.actions';
import { User } from '@app/shared/models/user.model';

export interface AuthenticatedUserState {
    loaded: boolean | null;
    user: User | null;
    errors: ErrorsModel | null;
    passwordChanged: boolean | null;
    saving: boolean;
    saved: boolean;
    updated: boolean | null;
    requestedResetPassword: boolean | null;
    resetPassword: boolean | null;
}

export const initialState: AuthenticatedUserState = {
    loaded: null,
    user: null,
    errors: null,
    saving: false,
    saved: false,
    passwordChanged: null,
    updated: null,
    requestedResetPassword: null,
    resetPassword: null,
};

export const authenticatedUserReducer = createReducer(
    initialState,
    on(
        authenticatedUserActions.getAuthenticatedUser,
        (state): AuthenticatedUserState => ({
            ...state,
            updated: null,
            loaded: null,
        }),
    ),
    on(
        authenticatedUserActions.getAuthenticatedUserSuccess,
        (state, payload): AuthenticatedUserState => ({
            ...state,
            loaded: true,
            user: payload.user,
        }),
    ),
    on(
        authenticatedUserActions.getAuthenticatedUserError,
        (state): AuthenticatedUserState => ({
            ...state,
            loaded: false,
        }),
    ),
    on(
        authenticatedUserActions.getAuthenticatedUserGracefull,
        (state): AuthenticatedUserState => ({
            ...state,
            updated: null,
            loaded: null,
        }),
    ),
    on(
        authenticatedUserActions.getAuthenticatedUserGracefullSuccess,
        (state, payload): AuthenticatedUserState => ({
            ...state,
            loaded: true,
            user: payload.user,
        }),
    ),
    on(
        authenticatedUserActions.getAuthenticatedUserGracefullError,
        (state): AuthenticatedUserState => ({
            ...state,
            loaded: false,
        }),
    ),
    on(
        authenticatedUserActions.clear,
        (state): AuthenticatedUserState => ({
            ...state,
            loaded: false,
            user: null,
            errors: null,
            passwordChanged: null,
            updated: null,
        }),
    ),
    on(
        authenticatedUserActions.updateAuthenticatedUser,
        (state): AuthenticatedUserState => ({
            ...state,
            loaded: true,
        }),
    ),
    on(
        authenticatedUserActions.updateAuthenticatedUserSuccess,
        (state): AuthenticatedUserState => ({
            ...state,
            loaded: false,
            updated: true,
        }),
    ),
    on(
        authenticatedUserActions.updateAuthenticatedUserError,
        (state, action): AuthenticatedUserState => ({
            ...state,
            loaded: false,
            errors: action.errors,
        }),
    ),

    on(
        authenticatedUserActions.requestResetPassword,
        (state): AuthenticatedUserState => ({
            ...state,
            saving: true,
            requestedResetPassword: false,
            errors: null,
        }),
    ),
    on(
        authenticatedUserActions.requestResetPasswordSuccess,
        (state): AuthenticatedUserState => ({
            ...state,
            saving: false,
            requestedResetPassword: true,
        }),
    ),
    on(
        authenticatedUserActions.requestResetPasswordError,
        (state): AuthenticatedUserState => ({
            ...state,
            saving: false,
            requestedResetPassword: false,
        }),
    ),
    // reset password
    on(
        authenticatedUserActions.resetPassword,
        (state): AuthenticatedUserState => ({
            ...state,
            saving: true,
            resetPassword: false,
            errors: null,
        }),
    ),
    on(
        authenticatedUserActions.resetPasswordSuccess,
        (state): AuthenticatedUserState => ({
            ...state,
            saving: false,
            resetPassword: true,
            errors: null,
        }),
    ),
    on(authenticatedUserActions.resetPasswordError, (state, action): AuthenticatedUserState => {
        if (!(action.response as HttpErrorResponse)?.status) {
            return {
                ...state,
                saving: false,
                resetPassword: false,
                errors: (action.response as { data: ErrorsModel }).data,
            };
        }
        return {
            ...state,
            saving: false,
            resetPassword: false,
            errors: null,
        };
    }),
);
