import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared';
import { UserModule } from '@app/user';

import { ShellComponent } from './components/shell/shell.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { MenuDropdownModule } from '@app/shared/components/menu-dropdown/menu-dropdown.module';
import { TimerModule } from '@app/shared/components/timer/timer.module';
import { UsersStoreModule } from '@app/store/chat/users/users.module';
import { WebsocketStoreModule } from '@app/store/websocket';

@NgModule({
    imports: [CommonModule, RouterModule, SharedModule, UserModule, WebsocketStoreModule, MenuDropdownModule, TimerModule, UsersStoreModule],
    declarations: [ShellComponent, HeaderComponent, FooterComponent],
})
export class ShellModule {}
