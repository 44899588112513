import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TimerService } from '@app/shared';

@Component({
    selector: 'app-timer',
    templateUrl: './timer.component.html',
    styleUrls: ['./timer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimerComponent {
    time$ = this.timerService.time$;

    constructor(private timerService: TimerService) {}
}
