import { createReducer, on } from '@ngrx/store';

import { LoaderActions } from '../actions/loader.actions';

export interface LoaderState {
    ids: string[];
    loading: boolean;
}

export const initialState: LoaderState = {
    ids: [],
    loading: false,
};

export const loaderReducer = createReducer(
    initialState,
    on(LoaderActions.showAction, (state, action): LoaderState => {
        const ids = [...state.ids, action.id];
        return {
            ...state,
            ids,
            loading: true,
        };
    }),
    on(LoaderActions.hideAction, (state, action): LoaderState => {
        const ids = [...state.ids];
        ids.splice(state.ids.indexOf(action.id), 1);
        return {
            ...state,
            ids,
            loading: ids.length > 0,
        };
    }),
    on(
        LoaderActions.claerAction,
        (state): LoaderState => ({
            ...state,
            ...initialState,
        }),
    ),
);
