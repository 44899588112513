import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertsService } from '@app/shared/alerts';
import { LoaderFacade } from '@app/store/loader';
import { CleanupService } from '../../services/cleanup.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { accountActions } from '../actions/account.actions';
import { ErrorsModel, RegisterError } from '../models';
import { HttpService } from '../services/http.service';
import { TokenService } from '@app/shared';
import { Router } from '@angular/router';
import { profileActions } from '@app/store/profile/actions/profile.actions';
import { UsersFacade } from '@app/store/chat/users';
import { ConversationFacade } from '@app/store/chat/conversation/facades/conversation.facade';

@Injectable()
export class AccountEffects {
    // register
    register$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(accountActions.registerAction),
            mergeMap(({ value }) => {
                this.loaderFacade.add('register');
                return this.httpService.register(value).pipe(
                    map(() => accountActions.registerSuccessAction()),
                    catchError((err: { error: RegisterError }) => of(accountActions.registerErrorAction({ errors: err.error }))),
                );
            }),
        );
    });

    registerSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(accountActions.registerSuccessAction),
                tap(() => {
                    this.alertsService.show('account.register.alert.success', 'success');
                    this.loaderFacade.remove('register');
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    registerError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(accountActions.registerErrorAction),
                tap(({ errors }) => {
                    this.handleRegisterError(errors);
                    this.loaderFacade.remove('register');
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    // login
    login$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(accountActions.loginAction),
            mergeMap(({ value }) => {
                this.loaderFacade.add('login');

                return this.httpService.login(value).pipe(
                    map((data) => accountActions.loginSuccessAction({ data })),
                    catchError((response: HttpErrorResponse) => of(accountActions.loginErrorAction({ response, email: value.email }))),
                );
            }),
        );
    });

    loginSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(accountActions.loginSuccessAction),
                tap((action) => {
                    this.tokenService.setToken(action.data);
                    this.alertsService.show('account.login.message.success.log-in', 'success');
                    this.loaderFacade.remove('login');
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    loginError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(accountActions.loginErrorAction),
                tap((payload) => {
                    if (payload.response.error?.message === 'exception.account_not_confirmed') {
                        this.alertsService.show('account.login.message.error.not-activated', 'danger');
                    } else {
                        this.alertsService.show('account.login.message.error.invalid-credentials', 'danger');
                    }

                    this.loaderFacade.remove('login');
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    // login
    logout$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(accountActions.logoutAction),
            mergeMap(() => {
                this.loaderFacade.add('logout');
                return this.httpService.logout().pipe(
                    map(() => accountActions.logoutSuccessAction()),
                    catchError(() => of(accountActions.logoutErrorAction())),
                );
            }),
        );
    });

    logoutSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(accountActions.logoutSuccessAction),
                tap(() => {
                    this.cleanupService.cleanup();
                    this.tokenService.removeToken();
                    this.alertsService.show('account.logout.message.success.logged-out', 'success');
                    this.loaderFacade.remove('logout');
                    this.usersFacade.reset();
                    this.conversationFacade.reset();
                    void this.router.navigate(['/']);
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    logoutError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(accountActions.logoutErrorAction),
                tap(() => {
                    this.loaderFacade.remove('logout');
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    // request reset password
    requestResetPassword$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(accountActions.requestResetPasswordAction),
            mergeMap((value) => {
                this.loaderFacade.add('request-reset-password');
                return this.httpService.requestResetPassword(value.email).pipe(
                    map(() => accountActions.requestResetPasswordSuccessAction()),
                    catchError((errors: ErrorsModel) => of(accountActions.requestResetPasswordErrorAction({ errors }))),
                );
            }),
        );
    });

    requestResetPasswordSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(accountActions.requestResetPasswordSuccessAction),
                tap(() => {
                    this.alertsService.show('request-reset-password.message.success', 'success');
                    this.loaderFacade.remove('request-reset-password');
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    requestResetPasswordError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(accountActions.requestResetPasswordErrorAction),
                tap(() => {
                    this.loaderFacade.remove('request-reset-password');
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    // reset password
    resetPassword$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(accountActions.resetPasswordAction),
            mergeMap((value) => {
                this.loaderFacade.add('reset-password');
                return this.httpService.resetPassword(value.email, value.token).pipe(
                    map(() => accountActions.resetPasswordSuccessAction()),
                    catchError((response: HttpErrorResponse) => of(accountActions.resetPasswordErrorAction({ response }))),
                );
            }),
        );
    });

    resetPasswordSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(accountActions.resetPasswordSuccessAction),
                tap(() => {
                    this.alertsService.show('reset-password.message.success', 'success');
                    this.loaderFacade.remove('reset-password');
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    resetPasswordError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(accountActions.resetPasswordErrorAction),
                tap(() => {
                    this.loaderFacade.remove('reset-password');
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    // activated
    activated$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(accountActions.activateAction),
            mergeMap((value) => {
                this.loaderFacade.add('activate');
                return this.httpService.activate(value.token).pipe(
                    map(() => accountActions.activateSuccessAction()),
                    catchError((errors: ErrorsModel) => of(accountActions.activateErrorAction({ errors }))),
                );
            }),
        );
    });

    activatedSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(accountActions.activateSuccessAction),
                tap(() => {
                    this.alertsService.show('account.activate.message.success', 'success');
                    this.loaderFacade.remove('activate');
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    activatedError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(accountActions.activateErrorAction),
                tap(() => {
                    this.loaderFacade.remove('activate');
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    editProfileSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(profileActions.editProfileSuccessAction),
            filter((action) => action.emailChanged === true),
            map(() => {
                return accountActions.logoutAction();
            }),
        );
    });

    constructor(
        private actions$: Actions,
        private httpService: HttpService,
        private loaderFacade: LoaderFacade,
        private alertsService: AlertsService,
        private cleanupService: CleanupService,
        private tokenService: TokenService,
        private router: Router,
        private usersFacade: UsersFacade,
        private conversationFacade: ConversationFacade,
    ) {}

    private handleRegisterError(errors: RegisterError) {
        let error = errors?.message;
        const keys = errors?.errors?.children ? Object.keys(errors?.errors?.children) : [];

        keys.forEach((key) => {
            const fieldError = errors?.errors?.children[key as keyof typeof errors.errors.children]?.errors?.[0];

            if (fieldError) {
                error = fieldError;
            }
        });

        if (error) {
            this.alertsService.show(error, 'danger');
        }
    }
}
