import { Injectable } from '@angular/core';
import { AlertsService } from '@app/shared/alerts';
import { LoaderFacade } from '@app/store/loader';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { messageActions } from '../actions/message.actions';
import { HttpService } from '../services/http.service';

@Injectable()
export class MessageEffects {
    getMessage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(messageActions.getMessageAction),
            mergeMap((action) => {
                this.loaderFacade.add('get-message');
                return this.httpService.getMessage(action.id).pipe(
                    map((payload) => messageActions.getMessageSuccessAction({ message: payload })),
                    catchError(() => of(messageActions.getMessageErrorAction())),
                );
            }),
        );
    });

    getMessageSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(messageActions.getMessageSuccessAction),
                tap(() => this.loaderFacade.remove('get-message')),
            );
        },
        {
            dispatch: false,
        },
    );

    getMessagesError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(messageActions.getMessageErrorAction),
                tap(() => this.loaderFacade.remove('get-message')),
            );
        },
        {
            dispatch: false,
        },
    );

    addMessage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(messageActions.addMessageAction),
            mergeMap((action) => {
                this.loaderFacade.add('add-message');
                return this.httpService.addMessage(action.value).pipe(
                    map((payload) => messageActions.addMessageSuccessAction({ message: payload })),
                    catchError(() => of(messageActions.addMessageErrorAction())),
                );
            }),
        );
    });

    addMessageSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(messageActions.addMessageSuccessAction),
                tap(() => {
                    this.loaderFacade.remove('add-message');
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    addMessageError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(messageActions.addMessageErrorAction),
                tap(() => {
                    this.loaderFacade.remove('add-message');
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    updateMessage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(messageActions.updateMessageAction),
            mergeMap((action) => {
                this.loaderFacade.add('update-message');
                return this.httpService.updateMessage(action.id, action.value).pipe(
                    map((payload) => messageActions.updateMessageSuccessAction({ message: payload })),
                    catchError(() => of(messageActions.updateMessageErrorAction())),
                );
            }),
        );
    });

    updateMessageSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(messageActions.updateMessageSuccessAction),
                tap(() => {
                    this.loaderFacade.remove('update-message');
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    updateMessageError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(messageActions.updateMessageErrorAction),
                tap(() => {
                    this.loaderFacade.remove('update-message');
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    deleteMessage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(messageActions.deleteMessageAction),
            mergeMap((action) => {
                this.loaderFacade.add('delete-message');
                return this.httpService.deleteMessage(action.id).pipe(
                    map((payload) => messageActions.deleteMessageSuccessAction({ id: payload.data.id })),
                    catchError(() => of(messageActions.deleteMessageErrorAction())),
                );
            }),
        );
    });

    deleteMessageSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(messageActions.deleteMessageSuccessAction),
                tap(() => {
                    this.loaderFacade.remove('delete-message');
                    this.alertsService.show('delete.system.message.success', 'success');
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    deleteMessageError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(messageActions.deleteMessageErrorAction),
                tap(() => this.loaderFacade.remove('delete-message')),
            );
        },
        {
            dispatch: false,
        },
    );

    constructor(private actions$: Actions, private httpService: HttpService, private loaderFacade: LoaderFacade, private alertsService: AlertsService) {}
}
