import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimerComponent } from './timer/timer.component';
import { TranslateModule } from '@ngx-translate/core';
import { DateModule } from '@app/shared/pipes/date/date.module';

@NgModule({
    imports: [CommonModule, TranslateModule, DateModule],
    declarations: [TimerComponent],
    exports: [TimerComponent],
})
export class TimerModule {}
