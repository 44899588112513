import { Injectable } from '@angular/core';
import { UrlTree, Router } from '@angular/router';
import { AuthenticatedUserFacade } from '@app/store/authenticated-user';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class UserLoggedInGuard  {
    constructor(private authenticatedUserFacade: AuthenticatedUserFacade, private router: Router) {}

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.authenticatedUserFacade.loaded$.pipe(
            take(1),
            map((loaded) => {
                if (loaded === false) {
                    return this.router.createUrlTree(['/account']);
                }
                return true;
            }),
        );
    }
}
