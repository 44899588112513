import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AlertActionModel } from '../../models/alert.model';
import { AlertsActionsService } from '../../services/alerts-actions.service';
import { AlertsService } from '../../services/alerts.service';

@Component({
    selector: 'app-action',
    styleUrls: ['./action.component.scss'],
    templateUrl: './action.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertActionComponent {
    @Input()
    action!: AlertActionModel;

    @Input()
    id!: string;

    constructor(private alertsActionsService: AlertsActionsService, private alertsService: AlertsService) {}

    onActionClick(event: MouseEvent) {
        event.preventDefault();
        this.alertsActionsService.send(this.action);
        if (this.action.hideOnAction) {
            this.alertsService.hide(this.id);
        }
    }
}
