import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { E2eFormGroupDirective } from './directive/from-group.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [E2eFormGroupDirective],
    exports: [E2eFormGroupDirective],
})
export class E2eSelectorModule {}
