import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { AUTHENTICATED_USER_FEATURE_KEY } from './keys';
import { authenticatedUserReducer } from './reducers/authenticated-user.reducer';
import { AuthenticatedUserEffects } from './effects/authenticated-user.effects';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(AUTHENTICATED_USER_FEATURE_KEY, authenticatedUserReducer),
        EffectsModule.forFeature([AuthenticatedUserEffects]),
    ],
})
export class AuthenticatedUserStoreModule {}
