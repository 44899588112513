import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

/**
 * Sets Correct language in <html lang={}> tag
 */
@Injectable({
    providedIn: 'root',
})
export class I18nHtmlLangService {
    constructor(@Inject(DOCUMENT) private document: Document) {}

    /**
     * Sets correct HTML Doc language
     */
    setLang(lang: string): void {
        const ln = lang.split('-')[0];
        this.document.documentElement.lang = ln;
    }
}
