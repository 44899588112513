import { Injectable } from '@angular/core';
import { LoaderFacade } from '@app/store/loader';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { plansActions } from '../actions/plans.actions';
import { HttpService } from '../services/http.service';

@Injectable()
export class PlansEffects {
    getPlans$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(plansActions.getPlansAction),
            mergeMap(() => {
                this.loaderFacade.add('get-plans');
                return this.httpService.getPlans().pipe(
                    map((response) => plansActions.getPlansSuccessAction({ payload: response })),
                    catchError(() => of(plansActions.getPlansErrorAction())),
                );
            }),
        );
    });

    getPlansSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(plansActions.getPlansSuccessAction),
                tap(() => this.loaderFacade.remove('get-plans')),
            );
        },
        {
            dispatch: false,
        },
    );

    getPlansError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(plansActions.getPlansErrorAction),
                tap(() => this.loaderFacade.remove('get-plans')),
            );
        },
        {
            dispatch: false,
        },
    );

    constructor(private actions$: Actions, private httpService: HttpService, private loaderFacade: LoaderFacade) {}
}
