import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment';

@Directive({
    selector: '[appIcon]',
})
export class IconDirective implements OnInit {
    @Input() appIcon = 'default';
    constructor(private elementRef: ElementRef) {}

    ngOnInit(): void {
        (this.elementRef.nativeElement as HTMLElement).innerHTML = `<svg><use xlink:href="${environment.icons}#${this.appIcon}" /></svg>`;
    }
}
