import { createAction, props } from '@ngrx/store';

enum HangoutActionType {
    PATCH_HANGOUT_ACTION = '[Hangout] Patch hangout action',
    PATCH_HANGOUT_SUCCESS_ACTION = '[Hangout] Patch hangout success action',
    PATCH_HANGOUT_ERROR_ACTION = '[Hangout] Patch hangout error action',
}

const patchHangoutAction = createAction(HangoutActionType.PATCH_HANGOUT_ACTION, props<{ videoCount: number; userId: string }>());
const patchHangoutSuccessAction = createAction(HangoutActionType.PATCH_HANGOUT_SUCCESS_ACTION, props<{ videoCount: number; userId: string }>());
const patchHangoutErrorAction = createAction(HangoutActionType.PATCH_HANGOUT_ERROR_ACTION);

export const hangoutActions = {
    patchHangoutAction,
    patchHangoutSuccessAction,
    patchHangoutErrorAction,
};
