import { MenuItemModel } from '@shared/models';

export const SidebarMenu: MenuItemModel[] = [
    {
        path: 'employee',
        exact: true,
        title: 'admin.menu.item.add-new-user',
        activeClass: 'active',
        access: 'role_admin',
        icon: 'person-plus-fill',
    },
    {
        path: 'employee',
        exact: true,
        title: 'admin.menu.item.add-new-psychologist',
        activeClass: 'active',
        access: 'role_service_admin',
        icon: 'person-plus-fill',
    },
    {
        path: 'customers',
        exact: true,
        title: 'admin.menu.item.list-users',
        activeClass: 'active',
        access: 'users_read',
        icon: 'people-fill',
    },
    {
        path: 'employees',
        exact: true,
        title: 'admin.menu.item.list-psychologist',
        activeClass: 'active',
        access: 'psychologist_read',
        icon: 'people-fill',
    },
    {
        path: 'messages/system',
        exact: true,
        title: 'admin.menu.item.system-messages',
        activeClass: 'active',
        access: 'system_messages',
        icon: 'envelope-fill',
    },
    {
        path: 'video-calls',
        exact: true,
        title: 'admin.menu.item.hangouts',
        activeClass: 'active',
        access: 'role_admin',
        icon: 'camera-video-fill',
    },
    {
        path: 'payments',
        exact: true,
        title: 'admin.menu.item.payments',
        activeClass: 'active',
        access: 'payments',
        icon: 'credit-card-fill',
    },
    {
        path: 'polls',
        exact: true,
        title: 'admin.menu.item.polls',
        activeClass: 'active',
        access: 'polls',
        icon: 'bar-chart-line-fill',
    },
    {
        path: 'discount-codes',
        exact: true,
        title: 'admin.menu.item.discount-codes',
        activeClass: 'active',
        access: 'discount_codes',
        icon: 'tag-fill',
    },
    {
        path: 'welcome/messages',
        exact: true,
        title: 'admin.menu.item.welcome-messages',
        activeClass: 'active',
        access: 'welcome_messages',
        icon: 'card-text',
    },
    {
        path: 'welcome-videos',
        exact: true,
        title: 'admin.menu.item.welcome-videos',
        activeClass: 'active',
        access: 'welcome_videos',
        icon: 'person-video',
    },
];
