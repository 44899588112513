import { Component } from '@angular/core';
import { SidebarMenu } from '@app/admin/config/menu';
import { PrivilegesService } from '@app/privileges';
import { Role } from '@app/privileges/enum/roles.enum';
import { MediaService } from '@app/shared/services/media.service';
import { AccountFacade } from '@app/store/account';
import { AuthenticatedUserFacade } from '@app/store/authenticated-user';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, map } from 'rxjs';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    sm$ = this.mediaService.media('sm');
    user$ = this.authenticatedUserFacade.user$;
    atLeastEmployee$ = this.privilegesService.hasAtLeast$(Role.Employee);
    username$ = combineLatest([this.user$, this.atLeastEmployee$]).pipe(
        map(([user, isEmployee]) => (isEmployee ? user.firstname + ' ' + user.lastname : user.nickname)),
    );

    menu$ = this.privilegesService.privileges$.pipe(
        map((privileges) => {
            return [...SidebarMenu]
                .filter((item) => privileges.includes(item.access))
                .map((item) => {
                    return { ...item, title: <string>this.translateService.instant(item.title), path: `/admin/${item.path}` };
                });
        }),
    );

    constructor(
        private mediaService: MediaService,
        private authenticatedUserFacade: AuthenticatedUserFacade,
        private accountFacade: AccountFacade,
        private privilegesService: PrivilegesService,
        private translateService: TranslateService,
    ) {}

    goToProfile() {
        return;
    }

    logout(): void {
        this.accountFacade.logout();
    }
}
