import { NgModule } from '@angular/core';
import { RouterModule, Routes, TitleStrategy } from '@angular/router';
import { HasPrivilegeGuard } from '@app/privileges';
import { UserLoggedInGuard, UserNotLoggedInGuard, UserPreloadGuard, websocketConnectGuard, websocketDisconnectGuard } from './shared/guards';
import { ShellComponent } from './shell/components/shell/shell.component';
import { TemplatePageTitleStrategy } from './core/title-strategy';

const routes: Routes = [
    {
        path: '',
        canActivate: [UserPreloadGuard],
        children: [
            {
                path: '',
                redirectTo: 'account',
                pathMatch: 'full',
            },
            {
                path: 'account',
                canActivate: [UserNotLoggedInGuard],
                loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
            },
            {
                path: '',
                component: ShellComponent,
                canActivate: [UserLoggedInGuard, websocketConnectGuard()],
                canDeactivate: [websocketDisconnectGuard()],
                children: [
                    {
                        path: 'admin',
                        canActivate: [HasPrivilegeGuard],
                        data: {
                            access: 'admin-panel',
                        },
                        loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
                    },
                    {
                        path: 'home',
                        loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
                    },
                    {
                        path: 'chat',
                        title: 'page.title.chat',
                        loadChildren: () => import('./chat/chat.module').then((m) => m.ChatModule),
                    },
                    {
                        path: 'profile',
                        title: 'page.title.profile',
                        loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
                    },
                    {
                        path: 'temp',
                        loadChildren: () => import('./temp/temp.module').then((m) => m.TempModule),
                    },
                    {
                        path: 'theme',
                        loadChildren: () => import('./theme/theme.module').then((m) => m.ThemeModule),
                    },
                ],
            },
        ],
    },
    {
        path: '**',
        redirectTo: 'home',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [{ provide: TitleStrategy, useClass: TemplatePageTitleStrategy }],
})
export class AppRoutingModule {}
