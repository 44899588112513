import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import {
    selectActivated,
    selectErrors,
    selectLogged,
    selectLoggedOut,
    selectRegistered,
    selectRequestedResetPassword,
    selectResetPassword,
    selectSaving,
} from '../selectors/account.selector';
import { accountActions } from '../actions/account.actions';
import { ErrorsModel, RegisterModel } from '../models';

@Injectable({
    providedIn: 'root',
})
export class AccountFacade {
    registered$ = this.store.select(selectRegistered).pipe(filter((data): data is boolean => data !== null));
    logged$ = this.store.select(selectLogged).pipe(filter((data): data is boolean => data !== null));
    loggedOut$ = this.store.select(selectLoggedOut).pipe(filter((data): data is boolean => data !== null));
    saving$ = this.store.select(selectSaving).pipe(filter((data): data is boolean => data !== null));
    requestedResetPassword$ = this.store.select(selectRequestedResetPassword).pipe(filter((data): data is boolean => data !== null));
    resetPassword$ = this.store.select(selectResetPassword).pipe(filter((data): data is boolean => data !== null));
    activated$ = this.store.select(selectActivated).pipe(filter((data): data is boolean => data !== null));
    errors$ = this.store.select(selectErrors).pipe(filter((data): data is ErrorsModel => data !== null));

    constructor(private store: Store) {}

    register(registerValue: RegisterModel): void {
        this.store.dispatch(accountActions.registerAction({ value: registerValue }));
    }

    login(email: string, password: string): void {
        this.store.dispatch(accountActions.loginAction({ value: { email, password } }));
    }

    logout(): void {
        this.store.dispatch(accountActions.logoutAction());
    }

    requestResetPassword(email: string): void {
        this.store.dispatch(accountActions.requestResetPasswordAction({ email }));
    }

    resetPassword(email: string, token: string): void {
        this.store.dispatch(accountActions.resetPasswordAction({ email, token }));
    }

    activate(token: string): void {
        this.store.dispatch(accountActions.activateAction({ token }));
    }

    clear(): void {
        this.store.dispatch(accountActions.clearAction());
    }
}
