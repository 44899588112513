export const environment = {
    production: false,
    sw: false,
    version: '2.0.0',
    serverUrl: 'https://api.wetalk.sandbox.cloud.codelabs.work/',
    wsServerUrl: 'wss://api.wetalk.sandbox.cloud.codelabs.work',
    payuUrl: 'https://secure.snd.payu.com/javascript/sdk',
    defaultLanguage: 'pl-PL',
    supportedLanguages: ['pl-PL'],
    icons: 'assets/svgs/icons.svg',
    table: {
        limit: 5,
    },
    countdownTime: 1500,
    countdownTimeBrbStatus: 600,
    countdownTimeOfflineStatus: 300,
    voiceRecordingTime: 180,
    maxPercentDiscount: 99,
    titlePrefix: 'WeTalk',
    messagesLimit: 24,
};
