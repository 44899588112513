import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConversationState } from '@app/store/chat/conversation/reducers/conversation.reducer';
import { CONVERSATION_FEATURE_KEY } from '@app/store/chat/conversation/keys';

const selectState = createFeatureSelector<ConversationState>(CONVERSATION_FEATURE_KEY);

const selectRoom = createSelector(selectState, (state) => state.room);
const selectMessages = createSelector(selectState, (state) => state.messages);
const selectPage = createSelector(selectState, (state) => state.page);
const selectLoading = createSelector(selectState, (state) => state.loading);
const selectHasMoreMessages = createSelector(selectState, (state) => state.has_more_messages);
const selectMessagesLoaded = createSelector(selectState, (state) => state.meesages_loaded);
const selectMessageSend = createSelector(selectState, (state) => state.message_send);
const selectMessagesPayload = createSelector(selectState, (state) => ({ roomId: state.room?.id, page: state.page, limit: state.limit }));

export const conversationSelectors = {
    selectRoom,
    selectMessages,
    selectPage,
    selectLoading,
    selectHasMoreMessages,
    selectMessagesLoaded,
    selectMessageSend,
    selectMessagesPayload,
};
