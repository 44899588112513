import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { selectMessages, selectLoaded, selectLoading, selectTotalItems } from '../selectors/messages.selector';
import { messagesActions } from '../actions/messages.actions';

@Injectable({
    providedIn: 'root',
})
export class MessagesFacade {
    messages$ = this.store.select(selectMessages).pipe(filter((messages) => messages !== null));
    totalItems$ = this.store.select(selectTotalItems).pipe(filter((totalItems) => totalItems !== null));
    loaded$ = this.store.select(selectLoaded).pipe(filter((loaded) => loaded !== null));
    loading$ = this.store.select(selectLoading).pipe(filter((loading) => loading !== null));

    constructor(private store: Store) {}

    getMessages(limit: number, page: number, sort: string | null = null, direction: string | null = null, term: string | null = null): void {
        this.store.dispatch(messagesActions.getMessagesAction({ limit, page, sort, direction, term }));
    }

    clear(): void {
        this.store.dispatch(messagesActions.clearAction());
    }
}
