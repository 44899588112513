import { createAction, props } from '@ngrx/store';
import { MessageModel, MessageValueModel } from '../models';

enum MessageActionTypes {
    GET_MESSAGE_ACTION = '[Message] Get message action',
    GET_MESSAGE_SUCCESS_ACTION = '[Message] Get message success action',
    GET_MESSAGE_ERROR_ACTION = '[Message] Get message error action',
    ADD_MESSAGE_ACTION = '[Message] Add message action',
    ADD_MESSAGE_SUCCESS_ACTION = '[Message] Add message success action',
    ADD_MESSAGE_ERROR_ACTION = '[Message] Add message error action',
    UPDATE_MESSAGE_ACTION = '[Message] Update message action',
    UPDATE_MESSAGE_SUCCESS_ACTION = '[Message] Update message success action',
    UPDATE_MESSAGE_ERROR_ACTION = '[Message] Update message error action',
    DELETE_MESSAGE_ACTION = '[Message] Delete message action',
    DELETE_MESSAGE_SUCCESS_ACTION = '[Message] Delete message success action',
    DELETE_MESSAGE_ERROR_ACTION = '[Message] Delete message  error action',
    CLEAR_ACTION = '[Message] Clear',
}

const getMessageAction = createAction(MessageActionTypes.GET_MESSAGE_ACTION, props<{ id: string }>());
const getMessageSuccessAction = createAction(MessageActionTypes.GET_MESSAGE_SUCCESS_ACTION, props<{ message: MessageModel }>());
const getMessageErrorAction = createAction(MessageActionTypes.GET_MESSAGE_ERROR_ACTION);

const addMessageAction = createAction(MessageActionTypes.ADD_MESSAGE_ACTION, props<{ value: MessageValueModel }>());
const addMessageSuccessAction = createAction(MessageActionTypes.ADD_MESSAGE_SUCCESS_ACTION, props<{ message: MessageModel }>());
const addMessageErrorAction = createAction(MessageActionTypes.ADD_MESSAGE_ERROR_ACTION);

const updateMessageAction = createAction(MessageActionTypes.UPDATE_MESSAGE_ACTION, props<{ id: string; value: MessageValueModel }>());
const updateMessageSuccessAction = createAction(MessageActionTypes.UPDATE_MESSAGE_SUCCESS_ACTION, props<{ message: MessageModel }>());
const updateMessageErrorAction = createAction(MessageActionTypes.UPDATE_MESSAGE_ERROR_ACTION);

const deleteMessageAction = createAction(MessageActionTypes.DELETE_MESSAGE_ACTION, props<{ id: string }>());
const deleteMessageSuccessAction = createAction(MessageActionTypes.DELETE_MESSAGE_SUCCESS_ACTION, props<{ id: string }>());
const deleteMessageErrorAction = createAction(MessageActionTypes.DELETE_MESSAGE_ERROR_ACTION);

const clearAction = createAction(MessageActionTypes.CLEAR_ACTION);

export const messageActions = {
    getMessageAction,
    getMessageSuccessAction,
    getMessageErrorAction,
    addMessageAction,
    addMessageSuccessAction,
    addMessageErrorAction,
    updateMessageAction,
    updateMessageSuccessAction,
    updateMessageErrorAction,
    deleteMessageAction,
    deleteMessageSuccessAction,
    deleteMessageErrorAction,
    clearAction,
};
