import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@app/shared/models';
import { map, Observable } from 'rxjs';
import { MessageModel, MessageValueModel } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private httpClient: HttpClient) {}

    getMessage(id: string): Observable<MessageModel> {
        return this.httpClient
            .addServer()
            .get<ApiResponse<MessageModel>>(`api/v1/panel/system/message/` + id)
            .pipe(map((response) => response.data));
    }

    addMessage(value: MessageValueModel): Observable<MessageModel> {
        return this.httpClient
            .addServer()
            .post<ApiResponse<MessageModel>>(`api/v1/panel/system/message`, value)
            .pipe(map((response) => response.data));
    }

    deleteMessage(id: string) {
        return this.httpClient.addServer().delete<ApiResponse<MessageModel>>(`api/v1/panel/system/message/` + id);
    }

    updateMessage(id: string, value: MessageValueModel): Observable<MessageModel> {
        const data = {
            message: value.message,
            sendAt: value.sendAt,
        };
        return this.httpClient
            .addServer()
            .patch<ApiResponse<MessageModel>>(`api/v1/panel/system/message/${id}`, data)
            .pipe(map((response) => response.data));
    }
}
