import { createReducer, on } from '@ngrx/store';
import { plansActions } from '../actions/plans.actions';
import { PlanModel } from '../models';

export interface PlansState {
    plans: PlanModel[] | null;
    loaded: boolean;
    loading: boolean;
}

export const initialState: PlansState = {
    plans: null,
    loaded: false,
    loading: false,
};

export const plansReducer = createReducer(
    initialState,
    on(
        plansActions.getPlansAction,
        (state): PlansState => ({
            ...state,
            loading: true,
        }),
    ),
    on(
        plansActions.getPlansSuccessAction,
        (state, payload): PlansState => ({
            ...state,
            loaded: true,
            loading: false,
            plans: payload.payload,
        }),
    ),
    on(
        plansActions.getPlansErrorAction,
        (state): PlansState => ({
            ...state,
            loading: false,
        }),
    ),
    on(
        plansActions.clearAction,
        (state): PlansState => ({
            ...state,
            ...initialState,
        }),
    ),
);
