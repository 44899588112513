import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { usersSelectors } from "@app/store/chat/users/selectors/users.selector";
import { UserStatus } from "@shared/models/user-status.model";
import { Subscription } from "../models";
import { usersActions } from "../actions/users.actions";

@Injectable({
    providedIn: 'root',
})
export class UsersFacade {
    users$ = this.store.select(usersSelectors.selectUsers);
    selectedUser$ = this.store.select(usersSelectors.selectSelectedUser);
    currentUser$ = this.store.select(usersSelectors.selectCurrentUser);
    typing$ = this.store.select(usersSelectors.selectTyping);
    unreadMessages$ = this.store.select(usersSelectors.selectUnreadMessages);
    isSelectedUserSubscriptionActive$ = this.selectedUser$.pipe();

    constructor(private store: Store) {}

    reset() {
        this.store.dispatch(usersActions.reset());
    }

    getUsers() {
        this.store.dispatch(usersActions.getUsers());
    }

    setCurrentUserStatus(status: UserStatus) {
        this.store.dispatch(usersActions.setCurrentUserStatus({ status }));
    }

    selectUser(id: string | null) {
        this.store.dispatch(
            usersActions.selectUser({
                value: id,
            }),
        );
    }

    readMessages() {
        this.store.dispatch(usersActions.readMessages());
    }

    isSubscriptionActive(subscription: Subscription | undefined) {
        return subscription?.active !== undefined && subscription?.active !== null && subscription.paid === 2 && Boolean(subscription.active.active) === true;
    }
}
