import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@app/shared/models';
import { map, Observable } from 'rxjs';
import { MessageModel } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private httpClient: HttpClient) {}

    getMessages(
        page: number,
        limit: number,
        sort: string | null,
        direction: string | null,
        term: string | null,
    ): Observable<{ items: MessageModel[]; totalItems: number }> {
        let params = new HttpParams().appendAll({ page, limit });
        if (sort) {
            params = params.append('sortColumn', sort);
        }
        if (direction) {
            params = params.append('sortType', direction);
        }
        if (term) {
            params = params.append('term', term);
        }
        return this.httpClient
            .addServer()
            .get<ApiResponse<MessageModel[]>>(`api/v1/panel/system/message`, { params: params })
            .pipe(
                map((response) => {
                    return {
                        items: response.data.map((item) => ({ ...item, sendAt: item.sendAt / 1000 })),
                        totalItems: <number>response.pagination?.totalItems,
                    };
                }),
            );
    }
}
