import { createAction, props } from '@ngrx/store';

export enum LoaderActionsTypes {
    SHOW_ACTION = '[Loader] Show loader action',
    HIDE_ACTION = '[Loader] Hide loader action',
    CLEAR_ACTION = '[Loader] Clear loader action',
}

const showAction = createAction(LoaderActionsTypes.SHOW_ACTION, props<{ id: string }>());
const hideAction = createAction(LoaderActionsTypes.HIDE_ACTION, props<{ id: string }>());
const claerAction = createAction(LoaderActionsTypes.CLEAR_ACTION);

export const LoaderActions = {
    showAction,
    hideAction,
    claerAction,
};
