import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TimerService } from '@app/shared';
import { AlertsService } from '@app/shared/alerts';
import { accountActions } from '@app/store/account/actions/account.actions';
import { ErrorsModel } from '@app/store/account/models';
import { LoaderFacade } from '@app/store/loader';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { authenticatedUserActions } from '../actions/authenticated-user.actions';
import { AuthenticatedUserFacade } from '../facades/authenticated-user.facade';
import { HttpService } from '../services/http.service';
import { User } from '@app/shared/models/user.model';

@Injectable()
export class AuthenticatedUserEffects {
    getUserWithoutLoader$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(authenticatedUserActions.getAuthenticatedUserWithoutLoader),
            mergeMap(() => {
                return this.httpService.getUser().pipe(
                    map((userDto) => authenticatedUserActions.getAuthenticatedUserSuccess({ user: new User(userDto) })),
                    catchError(() => of(authenticatedUserActions.getAuthenticatedUserError())),
                );
            }),
        );
    });

    getUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(authenticatedUserActions.getAuthenticatedUser),
            mergeMap(() => {
                this.loaderFacade.add('get-user');
                return this.httpService.getUser().pipe(
                    map((userDto) => authenticatedUserActions.getAuthenticatedUserSuccess({ user: new User(userDto) })),
                    catchError(() => {
                        return of(authenticatedUserActions.getAuthenticatedUserError());
                    }),
                );
            }),
        );
    });

    getUserSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(authenticatedUserActions.getAuthenticatedUserSuccess),
                tap(() => {
                    this.loaderFacade.remove('get-user');

                    this.timerService.startTimer();
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    getUserError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(authenticatedUserActions.getAuthenticatedUserError),
                tap(() => this.loaderFacade.remove('get-user')),
            );
        },
        {
            dispatch: false,
        },
    );

    authenticateUserAfterLogin$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(accountActions.loginSuccessAction),
            map(() => {
                return authenticatedUserActions.getAuthenticatedUser();
            }),
        );
    });

    getUserGracefull$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(authenticatedUserActions.getAuthenticatedUserGracefull),
            mergeMap(() => {
                this.loaderFacade.add('get-user');
                return this.httpService.getUserGracefull().pipe(
                    map((userDto) => authenticatedUserActions.getAuthenticatedUserGracefullSuccess({ user: new User(userDto) })),
                    catchError(() => {
                        return of(authenticatedUserActions.getAuthenticatedUserGracefullError());
                    }),
                );
            }),
        );
    });

    getUserGracefullSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(authenticatedUserActions.getAuthenticatedUserGracefullSuccess),
                tap(() => {
                    this.loaderFacade.remove('get-user');

                    this.timerService.startTimer();
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    getUserGracefullError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(authenticatedUserActions.getAuthenticatedUserGracefullError),
                tap(() => this.loaderFacade.remove('get-user')),
            );
        },
        {
            dispatch: false,
        },
    );

    updateUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(authenticatedUserActions.updateAuthenticatedUser),
            mergeMap((action) => {
                this.loaderFacade.add('update-user');
                return this.httpService.updateUser(action.firstName, action.lastName, action.phoneNumber).pipe(
                    map(() => authenticatedUserActions.updateAuthenticatedUserSuccess()),
                    catchError((errors: ErrorsModel) => of(authenticatedUserActions.updateAuthenticatedUserError({ errors }))),
                );
            }),
        );
    });

    updateUserSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(authenticatedUserActions.updateAuthenticatedUserSuccess),
                tap(() => {
                    this.alertsService.show('alerts.mydata.change.success', 'success', true);
                    this.loaderFacade.remove('update-user');
                    this.authenticatedUserFacade.loadUser();
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    updateUserError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(authenticatedUserActions.updateAuthenticatedUserError),
                tap(() => {
                    this.loaderFacade.remove('update-user');
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    requestResetPassword$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(authenticatedUserActions.requestResetPassword),
            mergeMap((value) => {
                this.loaderFacade.add('request-reset-password');
                return this.httpService.requestResetPassword(value.email).pipe(
                    map(() => authenticatedUserActions.requestResetPasswordSuccess()),
                    catchError((errors: ErrorsModel) => of(authenticatedUserActions.requestResetPasswordError({ errors }))),
                );
            }),
        );
    });

    requestResetPasswordSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(authenticatedUserActions.requestResetPasswordSuccess),
                tap(() => {
                    this.alertsService.show('request-reset-password.message.success', 'success');
                    this.loaderFacade.remove('request-reset-password');
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    requestResetPasswordError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(authenticatedUserActions.requestResetPasswordError),
                tap(() => {
                    this.alertsService.show('request-reset-password.message.error', 'danger');
                    this.loaderFacade.remove('request-reset-password');
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    resetPassword$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(authenticatedUserActions.resetPassword),
            mergeMap((value) => {
                this.loaderFacade.add('reset-password');
                return this.httpService.resetPassword(value.email, value.token).pipe(
                    map(() => authenticatedUserActions.resetPasswordSuccess()),
                    catchError((response: HttpErrorResponse) => of(authenticatedUserActions.resetPasswordError({ response }))),
                );
            }),
        );
    });

    resetPasswordSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(authenticatedUserActions.resetPasswordSuccess),
                tap(() => {
                    this.alertsService.show('reset-password.message.success', 'success');
                    this.loaderFacade.remove('reset-password');
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    resetPasswordError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(authenticatedUserActions.resetPasswordError),
                tap((response) => {
                    if ((response.response as HttpErrorResponse)?.status) {
                        this.alertsService.show('reset-password.message.error', 'danger');
                    }
                    this.loaderFacade.remove('reset-password');
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    constructor(
        private actions$: Actions,
        private httpService: HttpService,
        private loaderFacade: LoaderFacade,
        private alertsService: AlertsService,
        private authenticatedUserFacade: AuthenticatedUserFacade,
        private timerService: TimerService,
    ) {}
}
