import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@app/shared/models';
import { map, Observable } from 'rxjs';
import { PlanModel } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private httpClient: HttpClient) {}

    getPlans(): Observable<PlanModel[]> {
        return this.httpClient
            .addServer()
            .get<ApiResponse<PlanModel[]>>(`api/v1/subscription/plans`)
            .pipe(map((response) => response.data));
    }
}
