import { createReducer, on } from '@ngrx/store';
import { messageActions } from '../actions/message.actions';
import { MessageModel } from '../models';

export interface MessageState {
    id: string | null;
    message: MessageModel | null;
    deleted: boolean | null;
    deleting: boolean | null;
    loaded: boolean | null;
    loading: boolean | null;
    saving: boolean | null;
    saved: boolean | null;
}

export const initialState: MessageState = {
    id: null,
    message: null,
    loaded: null,
    loading: null,
    saving: null,
    saved: null,
    deleted: null,
    deleting: null,
};

export const messageReducer = createReducer(
    initialState,
    on(
        messageActions.getMessageAction,
        (state): MessageState => ({
            ...state,
            loading: true,
        }),
    ),
    on(
        messageActions.getMessageSuccessAction,
        (state, payload): MessageState => ({
            ...state,
            loaded: true,
            loading: false,
            message: payload.message,
        }),
    ),
    on(
        messageActions.getMessageErrorAction,
        (state): MessageState => ({
            ...state,
            loading: false,
        }),
    ),

    on(
        messageActions.addMessageAction,
        (state): MessageState => ({
            ...state,
            saving: true,
        }),
    ),

    on(
        messageActions.addMessageSuccessAction,
        (state, payload): MessageState => ({
            ...state,
            saving: false,
            saved: true,
            message: payload.message,
        }),
    ),

    on(
        messageActions.addMessageErrorAction,
        (state): MessageState => ({
            ...state,
            saving: false,
        }),
    ),

    on(
        messageActions.updateMessageAction,
        (state): MessageState => ({
            ...state,
            saving: true,
        }),
    ),

    on(
        messageActions.updateMessageSuccessAction,
        (state, payload): MessageState => ({
            ...state,
            saving: false,
            saved: true,
            message: payload.message,
        }),
    ),

    on(
        messageActions.updateMessageErrorAction,
        (state): MessageState => ({
            ...state,
            saving: false,
        }),
    ),

    on(
        messageActions.deleteMessageAction,
        (state): MessageState => ({
            ...state,
            deleting: true,
        }),
    ),

    on(
        messageActions.deleteMessageSuccessAction,
        (state, payload): MessageState => ({
            ...state,
            deleting: false,
            deleted: true,
            id: payload.id,
        }),
    ),

    on(
        messageActions.deleteMessageErrorAction,
        (state): MessageState => ({
            ...state,
            deleting: false,
        }),
    ),

    on(
        messageActions.clearAction,
        (state): MessageState => ({
            ...state,
            ...initialState,
        }),
    ),
);
