import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpClient } from '@angular/common/http';
import { Observable, catchError, map, mergeMap, timeout } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ApiClientIpInterceptor implements HttpInterceptor {
    constructor(private httpClient: HttpClient) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return this.getClientIp().pipe(
            timeout(1000),
            mergeMap((ip) => {
                return next.handle(
                    request.clone({
                        setHeaders: {
                            'X-CLIENT-IP': ip,
                        },
                    }),
                );
            }),
            catchError(() => {
                return next.handle(request);
            }),
        );
    }

    private getClientIp() {
        return this.httpClient.get<{ ip: string }>('https://api.ipify.org?format=json').pipe(map((res) => res.ip));
    }
}
