import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { User } from 'app/store/chat/users';
import { Typing, UnreadMessagesResponse } from '@app/shared/models/user.model';
import { UserStatus } from '@shared/models/user-status.model';

export const usersActions = createActionGroup({
    source: 'Chat Users',
    events: {
        Reset: emptyProps(),
        'Put Unread Messages': props<{ value: UnreadMessagesResponse[] }>(),
        'Set Unread Messages': props<{ value: UnreadMessagesResponse }>(),
        'Get User': props<{ userId: string }>(),
        'Put User': props<{ value: User }>(),
        'Get Users': emptyProps(),
        'Set Users': props<{ value: User[] }>(),
        'Put Users': props<{ value: User[] }>(),
        'Read Messages': emptyProps(),
        'Select User': props<{ value: string | null }>(),
        'Current User': props<{ value: User | null }>(),
        'Typing User': props<{ value: Typing }>(),
        'Set User Status': props<{ userId: string; status: UserStatus }>(),
        'Set Current User Status': props<{ status: UserStatus }>(),
        'Assign User': props<{ user: User; selectedUser?: string }>(),
        'Unassign User': props<{ user: User }>(),
        'Set Loading': props<{ value: boolean }>(),
        'Reduce User Hangouts': props<{ userId: string }>(),
        'Set User Hangouts': props<{ userId: string; videoCount: number }>(),
    },
});
