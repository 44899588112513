import { createAction, props } from '@ngrx/store';
import { PlanModel } from '../models';

enum PlansActionTypes {
    GET_PLANS_ACTION = '[Plans] Get Plans action',
    GET_PLANS_SUCCESS_ACTION = '[Plans] Get Plans success action',
    GET_PLANS_ERROR_ACTION = '[Plans] Get Plans error action',
    CLEAR_ACTION = '[Plans] Clear',
}

const getPlansAction = createAction(PlansActionTypes.GET_PLANS_ACTION);
const getPlansSuccessAction = createAction(PlansActionTypes.GET_PLANS_SUCCESS_ACTION, props<{ payload: PlanModel[] }>());
const getPlansErrorAction = createAction(PlansActionTypes.GET_PLANS_ERROR_ACTION);

const clearAction = createAction(PlansActionTypes.CLEAR_ACTION);

export const plansActions = {
    getPlansAction,
    getPlansSuccessAction,
    getPlansErrorAction,
    clearAction,
};
