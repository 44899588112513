import { Injectable } from '@angular/core';
import { AccountFacade } from '@app/store/account';
import { UsersFacade } from '@app/store/chat/users';
import { environment } from '@env/environment';
import { BehaviorSubject, fromEvent, Observable, Subject, take, takeUntil, tap, timer } from 'rxjs';
import { UserStatus } from '../models/user-status.model';
import { PrivilegesService } from '@app/privileges';
import { Role } from '@app/privileges/enum/roles.enum';

@Injectable({
    providedIn: 'root',
})
export class TimerService {
    public time$ = new BehaviorSubject(environment.countdownTime);

    private timerRunning$ = new BehaviorSubject(false);
    private stop$ = new Subject();
    private clickObserver$: Observable<Event> = fromEvent(document, 'click');
    private atLeastEmployee$ = this.privilegesService.hasAtLeast$(Role.Employee);

    constructor(
        private accountFacade: AccountFacade,
        private usersFacade: UsersFacade,
        private privilegesService: PrivilegesService,
    ) {
        this.clickObserver$

            .pipe(
                tap(() => {
                    this.resetTimer();
                }),
            )
            .subscribe();
    }

    public startTimer() {
        if (this.timerRunning$.value) {
            return;
        }

        this.time$.next(environment.countdownTime);
        this.timerRunning$.next(true);

        timer(0, 1000)
            .pipe(
                takeUntil(this.stop$),
                tap(() => {
                    this.time$.next(this.time$.value - 1);
                    if (this.time$.value === environment.countdownTimeOfflineStatus) {
                        this.setEmployeeStatus('offline');
                    }

                    if (this.time$.value === environment.countdownTimeBrbStatus) {
                        this.setEmployeeStatus('be-right-back');
                    }

                    if (this.time$.value === 0) {
                        this.accountFacade.logout();
                    }
                }),
            )
            .subscribe();
    }

    public resetTimer() {
        this.time$.next(environment.countdownTime);
    }

    public stopTimer() {
        this.stop$.next(null);
        this.timerRunning$.next(false);
    }

    private setEmployeeStatus(status: UserStatus) {
        this.atLeastEmployee$
            .pipe(
                take(1),
                tap((isEmployee) => {
                    if (!isEmployee) {
                        return;
                    }

                    this.usersFacade.setCurrentUserStatus(status);
                }),
            )
            .subscribe();
    }
}
