type UserStatusDto = 0 | 1 | 2;
type UserStatus = 'online' | 'offline' | 'be-right-back';

const statuses = [
    { key: 0, value: 'offline' },
    { key: 1, value: 'online' },
    { key: 2, value: 'be-right-back' },
];

const getStatus = (statusDto: UserStatusDto) => statuses.find((status) => status.key === statusDto)?.value as UserStatus;
const getStatusAsNumber = (userStatus: UserStatus) => statuses.find((status) => status.value === userStatus)?.key as UserStatusDto;

export { UserStatus, UserStatusDto, getStatus, getStatusAsNumber };
