import { createFeatureSelector, createSelector } from '@ngrx/store';
import { USERS_FEATURE_KEY } from '@app/store/chat/users/keys';
import { UsersState } from '@app/store/chat/users/reducers/users.reducer';

const selectState = createFeatureSelector<UsersState>(USERS_FEATURE_KEY);

const selectUsers = createSelector(selectState, (state) => state.users);
const selectSelectedUser = createSelector(selectState, (state) => state.users.find((user) => user.id === state.selected_user) || null);
const selectSelectedUserId = createSelector(selectState, (state) => state.selected_user);
const selectCurrentUser = createSelector(selectState, (state) => state.current_user);
const selectTyping = createSelector(selectState, (state) => state.typing);
const selectLastCount = createSelector(selectState, (state) => state.last_count);
const selectPage = createSelector(selectState, (state) => state.page);
const selectCanLoadMore = createSelector(selectState, (state) => state.can_load_more);
const selectUnreadMessages = createSelector(selectState, (state) => state.unread_messages);

export const usersSelectors = {
    selectUsers,
    selectSelectedUser,
    selectSelectedUserId,
    selectCurrentUser,
    selectTyping,
    selectLastCount,
    selectPage,
    selectCanLoadMore,
    selectUnreadMessages,
};
