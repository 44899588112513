import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class WindowService {
    constructor(@Inject(DOCUMENT) private doc: Document) {}

    get isTopWindow() {
        return window.self === window.top;
    }

    get window(): Window {
        return window;
    }

    get document(): Document {
        return this.doc;
    }

    get documentElement(): HTMLElement {
        return this.doc.documentElement;
    }

    scrollTop(top?: number): void {
        window.scrollTo(0, top ? top : 0);
    }

    redirectTopWindow(url: string) {
        if (this.isTopWindow) {
            this.window.location.href = url;
        } else if (this.window.top) {
            this.window.top.location.href = url;
        }
    }
}
