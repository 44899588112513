import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AlertActionModel } from '../models/alert.model';

@Injectable({
    providedIn: 'root',
})
export class AlertsActionsService {
    bus$ = new Subject<AlertActionModel>();

    send(action: AlertActionModel) {
        this.bus$.next(action);
    }
}
