import { Component, HostBinding } from '@angular/core';
import { OpenClose } from './animation/popup.animation';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    animations: [OpenClose],
})
export class SpinnerComponent {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    @HostBinding('@openClose') get(): void {}
}
