import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Grow } from '@app/shared/animations';
import { map } from 'rxjs';
import { AlertsService } from '../../services/alerts.service';

@Component({
    selector: 'app-alerts',
    styleUrls: ['./alerts.component.scss'],
    templateUrl: './alerts.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [Grow],
})
export class AlertsComponent {
    @Input()
    area?: string = 'window';

    @Input()
    static = false;

    alerts$ = this.alertsService.alerts$.pipe(
        map((alerts) => {
            if (this.area) {
                return alerts.filter((alert) => alert.area === this.area);
            }
            return alerts;
        }),
        map((alerts) => {
            return alerts.length > 0 ? alerts : [];
        }),
    );

    constructor(private alertsService: AlertsService) {}
}
