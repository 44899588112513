import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TitleComponent } from './title/title.component';
import { AvatarComponent } from './avatar/avatar.component';
import { SidebarWrapperComponent } from './sidebar-wrapper/sidebar-wrapper.component';

@NgModule({
    imports: [CommonModule],
    declarations: [TitleComponent, AvatarComponent, SidebarWrapperComponent],
    exports: [TitleComponent, AvatarComponent, SidebarWrapperComponent],
})
export class ComponentsModule {}
