import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { TokenService } from '@app/shared';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class SocketService {
    socket;
    emits = new Map<unknown, () => void>();

    constructor(private tokenService: TokenService) {
        this.socket = this.getInstance();
    }

    public emit<T>(key: string, params: unknown, handler?: (r: T) => void, errorHandler?: (r: unknown) => void, timeout = 10000) {
        const mapKey = key + '-' + Date.now();

        const emitFunction = () => {
            this.socket.timeout(timeout).emit(key, params, (err: unknown, response: T) => {
                this.emits.delete(mapKey);

                if (err && errorHandler) {
                    errorHandler(err);
                }

                if (!err && handler) {
                    handler(response);
                }
            });
        };

        this.emits.set(mapKey, emitFunction);

        emitFunction();
    }

    private getInstance() {
        const getToken = () => {
            return this.tokenService.getToken();
        };

        return io(environment.wsServerUrl, {
            transports: ['websocket'],
            path: '/ws',
            autoConnect: false,
            auth: {
                get userToken() {
                    return getToken();
                },
            },
        });
    }
}
